import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const ELockSolutions = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                E-Lock Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> E-Lock Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Secure Access</h4>
              <p className="mb-0">
                "Secure Access" with Findpath E-Lock ensures that only
                authorised individuals can reach your valuable assets. With its
                advanced locking mechanism, you maintain full control over who
                enters or accesses specific areas, providing a robust layer of
                security. This solution offers a modern approach to asset
                protection, minimising the risk of unauthorised access and
                giving you confidence in the safety of your possessions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Reliable Locking</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                "Reliable Locking" provides a dependable way to safeguard your
                assets. Designed to offer consistent performance in various
                conditions, Findpath E-Lock ensures your possessions are secure
                at all times. It eliminates the worries of traditional locks,
                offering a stronger, smarter, and more secure alternative for
                asset protection, keeping unauthorised access at bay with
                unwavering reliability.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Asset Protection</h4>
                  <p className="mb-0">
                    Findpath E-Lock offers robust security for your valuables,
                    allowing you to monitor and control access to your assets.
                    This solution reduces the risk of unauthorised use or theft
                    by providing reliable access control. With FindPath E-Lock,
                    you can feel assured that your belongings are secure and
                    protected, ensuring peace of mind no matter where they are
                    located.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Smart Security</h4>
                    <p className="mb-0">
                      "Smart Security" with Findpath E-Lock provides a modern
                      approach to protecting your assets, combining technology
                      with reliability. With FindPath E-Lock, you benefit from
                      advanced locking mechanisms and remote monitoring,
                      allowing you to safeguard your valuables more effectively.
                      This system offers flexibility, enabling you to control
                      access, receive alerts, and manage security from anywhere,
                      ensuring your assets remain safe and secure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Remote Monitoring</h4>
                    <p className="mb-0">
                      "Remote Monitoring" with Findpath E-Lock allows you to
                      keep a vigilant eye on your assets from anywhere. This
                      feature enables real-time access and control, letting you
                      monitor security and receive alerts instantly through your
                      mobile device or computer. With FindPath E-Lock, you stay
                      connected and informed, enhancing the safety of your
                      valuables no matter where you are.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Enhanced Safety</h4>
                  <p className="mb-5">
                    "Enhanced Safety" with Findpath E-Lock ensures that your
                    assets are protected with the latest security technology. By
                    providing advanced locking mechanisms and real-time alerts,
                    Findpath E-Lock minimises the risk of theft and unauthorised
                    access. This heightened level of protection offers peace of
                    mind, knowing that your valuables are securely monitored and
                    safeguarded at all times.
                  </p>
                  <h4 className="mb-3">Peaceful Assurance</h4>
                  <p className="mb-0">
                    "Peaceful Assurance" with Findpath E-Lock means you can rest
                    easy knowing your assets are secure. This solution provides
                    reliable protection with advanced locking technology and
                    real-time monitoring, ensuring that your valuables are
                    safeguarded against theft or unauthorised access. With
                    Findpath E-Lock, you have the confidence that your
                    belongings are always protected, offering you complete peace
                    of mind.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ELockSolutions;
