import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the homepage after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 10000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container py-5 text-center">
      <h1 className="display-4 font-weight-bold" style={{ color: '#1c5fa8' }}>
        Thank You!
      </h1>
      <p className="lead font-weight-bold">Your submission has been successfully received. We are committed to deliver the most seamless and accurate tracking solutions..!</p>
    </div>
  );
};

export default ThankYou;
