import React, { useState, useRef, useEffect } from "react";
import "../Login/Login.css";
import { useNavigate } from "react-router-dom";
import Loginimg from "../../Assests/Login.jpg";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [imageHeight, setImageHeight] = useState("auto");
  const [resendTimeout, setResendTimeout] = useState(0);

  const formRef = useRef(null);
  let timer;

  useEffect(() => {
    if (formRef.current) {
      const formHeight = formRef.current.clientHeight;
      setImageHeight(`${formHeight}px`);
    }
  }, [otpSent]);

  const handleSendOtp = () => {
    setOtpSent(true);
    startResendTimer(30);
  };

  const startResendTimer = (seconds) => {
    setResendTimeout(seconds);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (seconds > 0) {
        startResendTimer(seconds - 1);
      }
    }, 1000);
  };

  const handleResendOtp = () => {
    alert("Resending OTP...");
    setOtp("");
    setOtpSent(false);
    startResendTimer(30);
  };

  const handleVerifyOtp = () => {
    alert("OTP verified! Logging in...");
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <div className="login-container">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="card border-radius-20 card-3d">
              <div className="row no-gutters">
                {/* Image for mobile view */}
                <div className="col-12 d-md-none">
                  <img
                    src={Loginimg}
                    alt="Side Image"
                    className="img-fluid w-100 layer border-radius-10-left"
                  />
                </div>
                {/* Image for desktop view */}
                <div
                  className="col-12 col-md-6 d-none d-md-block"
                  style={{ minHeight: imageHeight }}
                >
                  <img
                    src={Loginimg}
                    alt="Side Image"
                    className="img-fluid w-100 layer border-radius-10-left"
                    style={{ height: imageHeight }}
                  />
                </div>
                <div
                  className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                  ref={formRef}
                >
                  <div>
                    <div className="text-center pt-5 logintext">
                      <h1 className="m-2 text-primary text-uppercase text-decoration-none font-weight-bold">
                        Welcome!
                      </h1>
                      <p>
                        Get access to your Orders, Wish list, and Recommendations.
                      </p>
                    </div>
                    <div className="card-body pt-2">
                      <form id="frmSignIn" method="post">
                        <div className="row">
                          <div className="form-group col-12">
                            <label className="form-label text-color-dark text-3">
                              Phone Number{" "}
                              <span className="text-color-danger">*</span>
                            </label>
                            <input
                              type="tel"
                              className="form-control form-control-lg text-4 border-color-hover-primary"
                              value={phoneNumber}
                              placeholder="Enter your number"
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>
                        {otpSent && (
                          <div className="row">
                            <div className="form-group col-12">
                              <label className="form-label text-color-dark text-3">
                                Enter OTP{" "}
                                <span className="text-color-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg text-4 border-color-hover-primary"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="form-group col-12">
                            {!otpSent ? (
                              <button
                                type="button"
                                className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 btn-custom"
                                onClick={handleSendOtp}
                              >
                                Send OTP
                              </button>
                            ) : (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 btn-custom"
                                  onClick={handleVerifyOtp}
                                >
                                  Verify OTP
                                </button>
                                {resendTimeout === 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-link text-color-dark text-decoration-none mt-2"
                                    onClick={handleResendOtp}
                                  >
                                    Resend OTP
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-link text-color-dark text-decoration-none mt-2"
                                    disabled
                                  >
                                    Resend OTP in {resendTimeout} seconds
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Remove this image element as it duplicates the mobile image */}
                {/* <div className="col-12 d-md-none">
                  <img
                    src={Loginimg}
                    alt="Side Image"
                    className="img-fluid w-100 layer border-radius-10-left"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
