import React from "react";
import blog1 from "../../Assests/Blog/blog-1.jpg";
import AuthorAvtar from "../../Assests/Avtar/avatar.jpg";
import Avtar1 from "../../Assests/Avtar/avatar-2.jpg";
import Avtar2 from "../../Assests/Avtar/avatar-3.jpg";
import Avtar3 from "../../Assests/Avtar/avatar-4.jpg";

const BlogHeader = () => (
  <section
    className="page-header page-header-modern page-header-lg"
    style={{ backgroundColor: "#e3eff9" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 className="text-color-dark font-weight-bold">
            Tips for Protecting Your Car
          </h1>
        </div>
        <div className="col-md-4 order-1 order-md-2 align-self-center">
          <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
            <li>
              <a
                href="demo-auto-services.html"
                className="text-color-default text-color-hover-primary text-decoration-none"
              >
                HOME
              </a>
            </li>
            <li className="active">BLOG</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const BlogMainContent = () => (
  <div
    className="appear-animation animated fadeInUpShorter appear-animation-visible"
    data-appear-animation="fadeInUpShorter"
    data-appear-animation-delay="1600"
    style={{ animationDelay: "1600ms" }}
  >
    <article>
      <div className="card border-0">
        <div className="card-body z-index-1 p-0">
          <p className="text-uppercase text-1 mb-3 text-color-default">
            <time pubdate="" dateTime="2024-01-10">
              10 Jan 2024
            </time>{" "}
            <span className="opacity-3 d-inline-block px-2">|</span> 3 Comments{" "}
            <span className="opacity-3 d-inline-block px-2">|</span> John Doe
          </p>

          <div className="post-image pb-0">
            <img
              className="card-img-top custom-border-radius-1"
              src={blog1}
              alt="Card Image"
            />
            
          </div>
          <h2 className="text-center mt-3">Tips for Protecting Your Car</h2>
          <div className="card-body p-0 mt-0">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              lectus lacus, rutrum sit amet placerat et, bibendum nec mauris.
              Duis molestie, purus eget placerat viverra, nisi odio gravida
              sapien, congue tincidunt nisl ante nec tellus. Vestibulum ante
              ipsum primis in faucibus orci luctus et ultrices posuere cubilia
              Curae; Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Fusce sagittis, massa fringilla consequat blandit, mauris ligula
              porta nisi, non tristique enim sapien vel nisl. Suspendisse
              vestibulum lobortis dapibus. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia Curae; Praesent
              nec tempus nibh. Donec mollis commodo metus et fringilla. Etiam
              venenatis, diam id adipiscing convallis, nisi eros lobortis
              tellus, feugiat adipiscing ante ante sit amet dolor. Vestibulum
              vehicula scelerisque facilisis. Sed faucibus placerat bibendum.
              Maecenas sollicitudin commodo justo, quis hendrerit leo consequat
              ac. Proin sit amet risus sapien, eget interdum dui. Proin justo
              sapien, varius sit amet hendrerit id, egestas quis mauris.
            </p>
            <p>
              Ut ac elit non mi pharetra dictum nec quis nibh. Pellentesque ut
              fringilla elit. Aliquam non ipsum id leo eleifend sagittis id a
              lorem. Sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Aliquam massa mauris, viverra et rhoncus
              a, feugiat ut sem. Quisque ultricies diam tempus quam molestie
              vitae sodales dolor sagittis. Praesent commodo sodales purus.
              Maecenas scelerisque ligula vitae leo adipiscing a facilisis nisl
              ullamcorper. Vestibulum ante ipsum primis in faucibus orci luctus
              et ultrices posuere cubilia Curae;
            </p>
            <p>
              Curabitur non erat quam, id volutpat leo. Nullam pretium gravida
              urna et interdum. Suspendisse in dui tellus. Cras luctus nisl vel
              risus adipiscing aliquet. Phasellus convallis lorem dui. Quisque
              hendrerit, lectus ut accumsan gravida, leo tellus porttitor mi, ac
              mattis eros nunc vel enim. Nulla facilisi. Nam non nulla sed nibh
              sodales auctor eget non augue. Pellentesque sollicitudin
              consectetur mauris, eu mattis mi dictum ac. Etiam et sapien eu
              nisl dapibus fermentum et nec tortor.
            </p>

            <hr className="my-5" />

            <div className="post-block post-author">
              <h3 className="text-color-primary text-capitalize font-weight-bold text-5 m-0 mb-3">
                Author
              </h3>
              <div className="img-thumbnail img-thumbnail-no-borders d-block pb-3">
                <a href="blog-post.html">
                  <img src={AuthorAvtar} className="rounded-circle" alt="" />
                </a>
              </div>
              <p>
                <strong className="name">
                  <a href="#" className="text-4 text-dark pb-2 pt-2 d-block">
                    John Doe
                  </a>
                </strong>
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                viverra euismod odio, gravida pellentesque urna varius vitae.
                Sed dui lorem, adipiscing in adipiscing et, interdum nec metus.
              </p>
            </div>

            <hr className="my-5" />

            <div id="comments" className="post-block post-comments">
              <h3 className="text-color-primary text-capitalize font-weight-bold text-5 m-0 mb-3">
                3 comments for "An Interview with John Doe"
              </h3>

              <ul className="comments">
                <li>
                  <div className="comment">
                    <div className="img-thumbnail img-thumbnail-no-borders d-none d-sm-block">
                      <img
                        className="avatar rounded-circle"
                        alt=""
                        src={Avtar1}
                      />
                    </div>
                    <div className="comment-block">
                      <div className="comment-arrow"></div>
                      <span className="comment-by">
                        <strong className="text-dark">John Doe</strong>
                        <span className="float-end">
                          <span>
                            {" "}
                            <a href="#">
                              <i className="fas fa-reply"></i> Reply
                            </a>
                          </span>
                        </span>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam viverra euismod odio, gravida pellentesque urna
                        varius vitae, gravida pellentesque urna varius vitae.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam viverra euismod odio, gravida pellentesque urna
                        varius vitae. Sed dui lorem, adipiscing in adipiscing
                        et, interdum nec metus. Mauris ultricies, justo eu
                        convallis placerat, felis enim ornare nisi, vitae mattis
                        nulla ante id dui.
                      </p>
                      <span className="date float-end">
                        January 12, 2024 at 1:38 pm
                      </span>
                    </div>
                  </div>

                  <ul className="comments reply">
                    <li>
                      <div className="comment">
                        <div className="img-thumbnail img-thumbnail-no-borders d-none d-sm-block">
                          <img
                            className="avatar rounded-circle"
                            alt=""
                            src={Avtar2}
                          />
                        </div>
                        <div className="comment-block">
                          <div className="comment-arrow"></div>
                          <span className="comment-by">
                            <strong className="text-dark">John Doe</strong>
                            <span className="float-end">
                              <span>
                                {" "}
                                <a href="#">
                                  <i className="fas fa-reply"></i> Reply
                                </a>
                              </span>
                            </span>
                          </span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam viverra euismod odio, gravida pellentesque
                            urna varius vitae, gravida pellentesque urna varius
                            vitae.
                          </p>
                          <span className="date float-end">
                            January 12, 2024 at 1:38 pm
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="comment">
                        <div className="img-thumbnail img-thumbnail-no-borders d-none d-sm-block">
                          <img
                            className="avatar rounded-circle"
                            alt=""
                            src={Avtar3}
                          />
                        </div>
                        <div className="comment-block">
                          <div className="comment-arrow"></div>
                          <span className="comment-by">
                            <strong className="text-dark">John Doe</strong>
                            <span className="float-end">
                              <span>
                                {" "}
                                <a href="#">
                                  <i className="fas fa-reply"></i> Reply
                                </a>
                              </span>
                            </span>
                          </span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam viverra euismod odio, gravida pellentesque
                            urna varius vitae, gravida pellentesque urna varius
                            vitae.
                          </p>
                          <span className="date float-end">
                            January 12, 2024 at 1:38 pm
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="comment">
                    <div className="img-thumbnail img-thumbnail-no-borders d-none d-sm-block">
                      <img
                        className="avatar rounded-circle"
                        alt=""
                        src={Avtar3}
                      />
                    </div>
                    <div className="comment-block">
                      <div className="comment-arrow"></div>
                      <span className="comment-by">
                        <strong className="text-dark">John Doe</strong>
                        <span className="float-end">
                          <span>
                            {" "}
                            <a href="#">
                              <i className="fas fa-reply"></i> Reply
                            </a>
                          </span>
                        </span>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                      <span className="date float-end">
                        January 12, 2024 at 1:38 pm
                      </span>
                    </div>
                  </div>
                </li>
              </ul>

              <div className="post-block mt-5 post-leave-comment">
                <h3 className="text-color-primary text-capitalize font-weight-bold text-5 m-0 mb-3">
                  Leave a Comment
                </h3>

                <form className="contact-form p-4 rounded bg-color-grey">
                  <div className="p-2">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="form-label mb-1 text-2">
                          Full Name
                        </label>
                        <input
                          type="text"
                          defaultValue=""
                          maxLength="100"
                          className="form-control text-3 h-auto py-2"
                          name="name"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-label mb-1 text-2">
                          Email Address
                        </label>
                        <input
                          type="email"
                          defaultValue=""
                          maxLength="100"
                          className="form-control text-3 h-auto py-2"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="row">
                      <div className="form-group col">
                        <label className="form-label mb-1 text-2">
                          Comment
                        </label>
                        <textarea
                          maxLength="5000"
                          rows="8"
                          className="form-control text-3 h-auto py-2"
                          name="comment"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="row">
                      <div className="form-group col">
                        <input
                          type="submit"
                          defaultValue="Post Comment"
                          className="btn btn-primary btn-modern"
                          data-loading-text="Loading..."
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
);

const BlogPage = () => (
  <div role="main" className="main">
    <BlogHeader />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <BlogMainContent />
        </div>
      </div>
    </div>
  </div>
);

export default BlogPage;
