import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import CountUp from "react-countup";
import "aos/dist/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HomePage.css"; // Import your CSS for styling
import Industry from "../../Components/Industry/Industry";
import Clients from "../../Components/ClientSection/Clients";


import Products from "../../Components/Product/Product";
// import backgroundImage from "../../Assests/slider/s1.jpg"; Correct path to the background image
import generic_1 from "../../Assests/generic-1.png";
import generic_2 from "../../Assests/generic-1-1.png";
import generic_3 from "../../Assests/generic-1-2.png";
import generic_4 from "../../Assests/generic-1-3.png";

import oneyearwarranty from "../../Assests/Svg/oneyearwarranty.svg";
import gstbiiling from "../../Assests/Svg/gstbiiling.svg";
import freeexpressdelivery from "../../Assests/Svg/freeexpressdelivery.svg";
import sevendayreplacement from "../../Assests/Svg/7dayreplacement.svg";

import FleetManagementSolution from "../../Assests/solutions/fleet-management-solution.jpg";
import VideoTelematicsSolution from "../../Assests/solutions/video-telematics-solution.png";
import FuelMonitoringSolution from "../../Assests/solutions/fuel-monitoring-solution.png";
import DriverBehaviourSolution from "../../Assests/solutions/driver-behaviour-solution.jpg";
// import svg_1 from "../../Assests/svg1.svg";
// import svg_2 from "../../Assests/svg2.svg";
// import svg_3 from "../../Assests/svg3.svg";

import Slider from "../../Components/Slider/Slider";
import Review from "../../Components/Review/Review";

AOS.init();
const HomePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-back", // Easing options
    });
  }, []);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Change this value as needed, represents the percentage of visibility required to trigger animation
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once content is visible
        }
      });
    }, options);

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    // Cleanup function
    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);
  const navigate = useNavigate();

  return (
    <>
  
      <Slider />

      <div ref={contentRef} className={isVisible ? "animate" : "hide"}>
        <section className=" section custom-section-background position-relative border-0 overflow-hidden m-0 p-0">
          {/* <div
          className="position-absolute top-0 left-0 right-0 bottom-0 animated fadeIn"
          style={{ animationDelay: "600ms" }}
        >
          <div
            className="background-image-wrapper custom custom-background-style-1 position-absolute top-0 left-0 right-0 bottom-0 animated kenBurnsToRight"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: "center right",
              animationDuration: "5s",
              backgroundSize: "contain",
            }}
          ></div>
        </div>
        <div className="container position-relative py-sm-5 my-5">
          <svg
            className="custom-svg-1 d-none d-sm-block"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 649 578"
          >
            <path
              fill="#FFF"
              d="M-225.5,154.7l358.45,456.96c7.71,9.83,21.92,11.54,31.75,3.84l456.96-358.45c9.83-7.71,11.54-21.92,3.84-31.75
              L267.05-231.66c-7.71-9.83-21.92-11.54-31.75-3.84l-456.96,358.45C-231.49,130.66-233.2,144.87-225.5,154.7z"
            />
            <path
              className="animated customLineAnim"
              fill="none"
              stroke="#1C5FA8"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              d="M416-21l202.27,292.91c5.42,7.85,3.63,18.59-4.05,24.25L198,603"
              style={{ animationDelay: "300ms", animationDuration: "5s" }}
            />
          </svg>
          <div className="row mb-5 position-relative z-index-1">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="overflow-hidden mb-1">
                <h2
                  className="font-weight-bold text-color-grey text-4-5 line-height-2 line-height-sm-7 mb-0 appear-animation "
                  style={{
                    opacity: '1',
                    animationName: 'maskUp',
                    animationDuration: '2s',
                    animationDelay: "800",
                  }}
                >
                  Mechanics Who Are Ready To Work For You
                </h2>
              </div>
              <h1
                className="text-color-dark font-weight-bold text-8 pb-2 mb-4 appear-animation "
                style={{
                  opacity: '1',
                  animationName: 'fadeInUpShorter',
                  animationDuration: '3s',
                  animationDelay: "1100",
                }}
              >
                Reliable And Knowledgeable Mechanics Located In Los Angeles, CA
              </h1>
              <a
                href="#"
                className="btn btn-primary custom-btn-border-radius custom-btn-arrow-effect-1 font-weight-bold text-3 px-5 btn-py-3 appear-animation "
                style={{
                  opacity: '1',
                  animationName: 'fadeInUpShorter',
                  animationDuration: '4s',
                  animationDelay: "1300",
                }}
              >
                GET STARTED
                <svg
                  className="ms-2"
                  version="1.1"
                  viewBox="0 0 15.698 8.706"
                  width="17"
                  xmlSpace="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <polygon
                    stroke="#FFF"
                    strokeWidth="0.1"
                    fill="#FFF"
                    points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div> */}
        </section>
        <section
          className="section border-0 m-0 appear-animation"
          style={{
            opacity: "1",
            animationName: "fadeIn",
            animationDuration: "6s",
            animationDelay: "1200",
          }}
        >
          <div className="container align-items-center">
            <div className="row align-items-center ">
              <div className="col-lg-8 col-sm-9 col-md-12  text-center text-lg-end mb-4 mb-lg-0">
                <h2
                  className="font-weight-bold text-color-primary text-7 line-height-1 mb-1 appear-animation "
                  style={{
                    opacity: "1",
                    animationName: "fadeInLeftShorterPlus",
                    animationDuration: "5s",
                    animationDelay: "1700",
                  }}
                >
                  Findpath Provide Complete GPS Tracking Solutions
                </h2>
                <p
                  className="font-weight-bold text-4 mb-0 appear-animation"
                  style={{
                    opacity: "1",
                    animationName: "fadeInLeftShorterPlus",
                    animationDuration: "5s",
                    animationDelay: "1900",
                  }}
                ></p>
              </div>
              <div className="col-lg-4 text-center text-lg-start ps-lg-4">
                <Link
                  to="/getademo"
                  className="btn btn-primary btn-outline custom-btn-border-radius font-weight-bold text-2 ms-lg-2 custom-btn-responsive"
                  style={{
                    opacity: "1",
                    animationName: "fadeInLeftShorterPlus",
                    animationDuration: "3s",
                    animationDelay: "1500",
                  }}
                >
                  Get A Demo
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section border-0 m-0">
          <div
            className="container py-1 my-1"
            style={{
              backgroundColor: "#ffffff",
              borderBottom: "1px solid #dcdcdc", // Adjust the color and width as needed
            }}
          >
            <div className="row text-center">
              <div className="col-md-3 mb-4">
                <div className="feature-box p-3 text-center row">
                  <div className="icon-wrapper mb-3">
                    <img
                      src={oneyearwarranty}
                      alt="1 Year Warranty"
                      className="img-fluid warranty-icon"
                    />
                  </div>
                  <h4 className="font-weight-light mb-2">
                    <strong>1 Year</strong> Warranty
                  </h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="feature-box p-3 text-center row">
                  <div className="icon-wrapper mb-3">
                    <img
                      src={sevendayreplacement}
                      alt="7-Day Replacement"
                      className="img-fluid replacement-icon"
                    />
                  </div>
                  <h4 className="font-weight-light mb-2">
                    <strong>7-Day</strong> Replacement
                  </h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="feature-box p-3 text-center row">
                  <div className="icon-wrapper mb-2">
                    <img
                      src={freeexpressdelivery}
                      alt="Free Express Delivery"
                      className="img-fluid express-svg"
                    />
                  </div>
                  <h4 className="font-weight-light mb-2">
                    <strong>Free Express</strong> Delivery
                  </h4>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div
                  className="feature-box p-3 text-center row "
                  style={{ position: "relative", top: "4px" }}
                >
                  <div className="icon-wrapper mb-3 ">
                    <img
                      src={gstbiiling}
                      alt="GST Billing"
                      className="img-fluid gst-icon"
                    />
                  </div>
                  <h4 className="font-weight-light mb-2">
                    <strong>GST</strong> Billing
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container my-5 pt-md-4 pt-xl-0" ref={contentRef}>
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div className="overflow-hidden">
                <h2
                  className={`font-weight-bold text-color-dark line-height-1 mb-0 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  About Company
                </h2>
              </div>
              <div className="custom-divider divider divider-primary divider-small my-3">
                <hr
                  className={`my-0 ${isVisible ? "aos-animate" : ""}`}
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="600"
                />
              </div>
              <p
                className={`font-weight-light text-3-5 mb-4 ${
                  isVisible ? "aos-animate" : ""
                }`}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                Founded in 2018 in Delhi, SM Surveillance Private Limited is a
                leading provider of premium GPS tracking solutions in India. We
                specialize in real-time tracking for assets, vehicles, and
                individuals, offering cutting-edge solutions like E-Lock, Fleet
                Management, Fuel Monitoring, Video Telematics, Driver Behaviour
                analysis, Route Planning, and Electric Vehicle Management. Our
                advanced technology ensures accurate location data, providing
                security and peace of mind.
              </p>
              <p
                className={`font-weight-light text-3-5 pb-1 mb-4 ${
                  isVisible ? "aos-animate" : ""
                }`}
                data-aos="fade-up"
                data-aos-duration="1400"
                data-aos-delay="1200"
              >
                We are dedicated to delivering top-tier technology and
                exceptional customer service, empowering businesses with
                data-driven insights to enhance efficiency and safeguard their
                assets. At SM Surveillance Private Limited, we are committed to
                exceeding customer expectations with our innovative tracking
                solutions.
              </p>
              <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                <a
                  role="button"
                  onClick={() => navigate("/aboutus")}
                  className={`btn btn-primary custom-btn-border-radius font-weight-bold text-3 px-5 btn-py-3 me-sm-2 mb-3 mb-sm-0 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  data-aos-delay="1500"
                >
                  VIEW MORE
                </a>
                {/* <div
                  className={`feature-box align-items-center border border-top-0 border-end-0 border-bottom-0 custom-remove-mobile-xs-border-left ms-sm-4 ps-sm-4 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-up"
                  data-aos-duration="1800"
                  data-aos-delay="1600"
                >
                  <div className="feature-box-icon bg-transparent">
                    <i className="icons icon-phone text-6 text-color-dark"></i>
                  </div>
                  <div className="feature-box-info line-height-2 ps-1">
                    <span className="d-block text-1 font-weight-semibold text-color-default">
                      CALL US NOW
                    </span>
                    <strong className="text-4-5">
                      <a
                        href="tel:+1234567890"
                        className="text-color-dark text-color-hover-primary text-decoration-none"
                      >
                        +91 9958799582
                      </a>
                    </strong>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className={`col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 ${
                isVisible ? "aos-animate" : ""
              }`}
              data-aos="fade-in"
              data-aos-duration="1400"
              data-aos-delay="1300"
            >
              <div className="position-relative">
                <div
                  data-plugin-float-element
                  data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}"
                >
                  <img
                    src={generic_1}
                    className="img-fluid "
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration="1400"
                    data-aos-delay="1300"
                  />
                </div>
                <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "25%", left: "7%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 0.5, 'transition': true, 'transitionDuration': 2000, 'isInsideSVG': false}"
                  >
                    <img
                      src={generic_2}
                      className="appear-animation "
                      alt=""
                      data-aos="fade-left"
                      data-aos-duration="1600"
                      data-aos-delay="1500"
                    />
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50 ms-5 pb-5 ms-xl-0"
                  style={{ top: "32%", left: "85%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 1, 'transition': true, 'transitionDuration': 1500, 'isInsideSVG': false}"
                  >
                    <img
                      src={generic_3}
                      className="appear-animation"
                      alt=""
                      data-aos="fade-left"
                      data-aos-duration="1800"
                      data-aos-delay="1700"
                    />
                  </div>
                </div>
                <div
                  className="position-absolute transform3dxy-n50"
                  style={{ top: "90%", left: "19%" }}
                >
                  <div
                    data-plugin-float-element
                    data-plugin-options="{'startPos': 'top', 'speed': 2, 'transition': true, 'transitionDuration': 2500, 'isInsideSVG': false}"
                  >
                    <img
                      src={generic_4}
                      className="appear-animation"
                      alt=""
                      data-aos="fade-left"
                      data-aos-duration="2000"
                      data-aos-delay="1900"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-lg-4 text-center px-lg-5 mb-5 mb-lg-0">
              <a
                href="demo-auto-services-services-detail.html"
                className="text-decoration-none"
              >
                <div
                  className={`custom-icon-box-style-1 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-in"
                  data-aos-duration="2200"
                  data-aos-delay="2000"
                  data-plugin-options="{'accY': -300}"
                >
                  <div className="custom-icon-style-1 mb-4">
                    {/* Updated SVG */}
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50" // Adjusted to match your previous size
                      height="50" // Adjusted to match your previous size
                      viewBox="0 0 395.71 395.71"
                    >
                      <g>
                        <path
                          d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
          c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
          C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
          c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                        />
                      </g>
                    </svg>
                  </div>

                  <h3 className="text-transform-none font-weight-bold text-color-dark line-height-3 text-4-5 px-3 px-xl-5 my-2">
                    GPS
                  </h3>
                  <p>
                    GPS hardware tracker is a device that uses satellite signals
                    to pinpoint and transmit location data for accurate tracking
                    and monitoring purposes.
                  </p>
                </div>
              </a>
            </div>

            <div className="col-lg-4 text-center px-lg-5 mb-5 mb-lg-0">
              <a
                href="demo-auto-services-services-detail.html"
                className="text-decoration-none"
              >
                <div
                  className={`custom-icon-box-style-1 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-in"
                  data-aos-duration="2000"
                  data-aos-delay="1800"
                  data-plugin-options="{'accY': -300}"
                >
                  <div className="custom-icon-style-1 mb-4">
                    {/* Updated SVG */}
                    <svg
                      fill="#000000"
                      height="50"
                      width="50"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 330 330"
                      className="feather feather-e-lock icon-svg"
                    >
                      <g id="XMLID_504_">
                        <path
                          id="XMLID_505_"
                          d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
        S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M207.481,219.356l-42.5,42.5
        c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.606-4.394l-21.25-21.25c-5.858-5.858-5.858-15.354,0-21.213
        c5.857-5.858,15.355-5.858,21.213,0l10.644,10.643l31.894-31.893c5.857-5.858,15.355-5.858,21.213,0
        C213.34,204.002,213.34,213.498,207.481,219.356z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
                        />
                      </g>
                    </svg>
                  </div>

                  <h3 className="text-transform-none font-weight-bold text-color-dark line-height-3 text-4-5 px-xl-5 my-2 mx-4">
                    E-Lock
                  </h3>
                  <p>
                    GPS tracker software interprets satellite data, displaying
                    precise locations and routes, enabling real-time monitoring
                    and historical tracking functionalities.
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 text-center px-lg-5">
              <a
                href="demo-auto-services-services-detail.html"
                className="text-decoration-none"
              >
                <div
                  className={`custom-icon-box-style-1 ${
                    isVisible ? "aos-animate" : ""
                  }`}
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="1400"
                  data-plugin-options="{'accY': -300}"
                >
                  <div className="custom-icon-style-1 mb-4">
                    {/* Updated SVG */}
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50px" // Adjusted to match your original size
                      height="50px" // Adjusted to match your original size
                      viewBox="0 0 355.484 355.484"
                    >
                      <g>
                        <g>
                          <g>
                            <path
                              d="M177.754,132.652c-35.005,0-63.489,28.462-63.489,63.474c0,35.008,28.484,63.488,63.489,63.488
              c34.996,0,63.477-28.48,63.477-63.488C241.231,161.108,212.75,132.652,177.754,132.652z"
                            />
                          </g>
                          <g>
                            <path
                              d="M240.384,76.63l-29.388-29.388h-65.843L119.691,76.63H0v231.611h355.484V76.63H240.384z M68.5,123.645H35.209v-13.718
              H68.5V123.645z M177.754,274.482c-43.208,0-78.363-35.158-78.363-78.356c0-43.202,35.155-78.36,78.363-78.36
              c43.187,0,78.351,35.158,78.351,78.36C256.105,239.33,220.941,274.482,177.754,274.482z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>

                  <h3 className="text-transform-none font-weight-bold text-color-dark line-height-3 text-4-5 px-4 px-xl-5 my-2">
                    Dashcams
                  </h3>
                  <p>
                    GPS tracker SIM card enables location data transmission via
                    cellular networks, facilitating real-time tracking and
                    monitoring capabilities.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Products />
        <section
          className="section section-primary border-0 m-0 appear-animation appear-animation-visible"
          data-aos="fade-in"
        >
          <div className="container">
            <div className="row counters counters-sm pb-4 pt-3">
              <div
                className="col-sm-6 col-lg-3 mb-5 mb-lg-0"
                data-aos="fade-up"
              >
                <div className="counter">
                  <i className="icons icon-user text-color-light"></i>
                  <strong className="text-color-light font-weight-extra-bold">
                    <CountUp end={25000} duration={4} suffix="+" />
                  </strong>
                  <label className="text-4 mt-1 text-color-light">
                    Happy Clients
                  </label>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-3 mb-5 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="counter">
                  <i className="icons icon-badge text-color-light"></i>
                  <strong className="text-color-light font-weight-extra-bold">
                    <CountUp end={5} duration={4} suffix="+" />
                  </strong>
                  <label className="text-4 mt-1 text-color-light">
                    Years In Business
                  </label>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-3 mb-5 mb-sm-0"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="counter">
                  <i className="icons icon-graph text-color-light"></i>
                  <strong className="text-color-light font-weight-extra-bold">
                    <CountUp end={100000} duration={4} suffix="+" />
                  </strong>
                  <label className="text-4 mt-1 text-color-light">
                    Business High Score
                  </label>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-3"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="counter">
                  <i className="icons icon-flag text-color-light"></i>
                  <strong className="text-color-light font-weight-extra-bold">
                    <CountUp end={20} duration={4} suffix="+" />
                  </strong>
                  <label className="text-4 mt-1 text-color-light">States</label>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="container py-5 my-5">
          <div class="row justify-content-center">
            <div class="col-lg-9 col-xl-8 text-center">
              <div class="overflow-hidden">
                <h2
                  class="font-weight-bold text-color-dark line-height-1 mb-0 "
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  Solution
                </h2>
              </div>
              <div class="d-inline-block custom-divider divider divider-primary divider-small my-3">
                <hr class="my-0 " data-aos="fade-up" data-aos-delay="800" />
              </div>
              <p
                class="font-weight-light text-3-5 mb-5 "
                data-aos="fade-up"
                data-aos-delay="1000"
              >
                Cras a elit sit amet leo accumsan volutpat. Suspendisse
                hendrerit vehicula leo, vel efficitur felis ultrices non.
                Integer aliquet ullamcorper dolor, quis sollicitudin.
              </p>
            </div>
          </div>
          <div
            class="row row-gutter-sm mb-5 "
            data-aos="fade-up"
            data-aos-delay="1000"
          >
            <div class="col-sm-6 col-lg-3 text-center mb-4 mb-lg-0">
              <a
                href="demo-auto-services-services-detail.html"
                class="text-decoration-none"
              >
                <div class="custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
                  <div class="thumb-info-wrapper">
                    <img
                      src={FleetManagementSolution}
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h3 class="text-transform-none font-weight-bold text-5 mt-2 mb-0">
                    Fleet Management Solution
                  </h3>
                </div>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3 text-center mb-4 mb-lg-0">
              <a
                href="demo-auto-services-services-detail.html"
                class="text-decoration-none"
              >
                <div class="custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
                  <div class="thumb-info-wrapper">
                    <img
                      src={VideoTelematicsSolution}
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h3 class="text-transform-none font-weight-bold text-5 mt-2 mb-0">
                    Video Telematics Solution
                  </h3>
                </div>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3 text-center mb-4 mb-sm-0">
              <a
                href="demo-auto-services-services-detail.html"
                class="text-decoration-none"
              >
                <div class="custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
                  <div class="thumb-info-wrapper">
                    <img
                      src={FuelMonitoringSolution}
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h3 class="text-transform-none font-weight-bold text-5 mt-2 mb-0">
                    Fuel Monitoring Solution
                  </h3>
                </div>
              </a>
            </div>
            <div class="col-sm-6 col-lg-3 text-center">
              <a
                href="demo-auto-services-services-detail.html"
                class="text-decoration-none"
              >
                <div class="custom-thumb-info-style-1 thumb-info thumb-info-no-borders thumb-info-no-borders-rounded thumb-info-lighten">
                  <div class="thumb-info-wrapper">
                    <img
                      src={DriverBehaviourSolution}
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <h3 class="text-transform-none font-weight-bold text-5 mt-2 mb-0">
                    Driver Behaviour Solution
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <Link
                to="/all-solutions"
                href="demo-auto-services-services.html"
                class="btn btn-primary custom-btn-border-radius font-weight-bold text-3 btn-px-5 btn-py-3 "
                data-aos="fade-up"
                data-aos-delay="500"
              >
                VIEW ALL SOLUTIONS
              </Link>
            </div>
          </div>
        </div>
        <Industry />
        <Clients />
        <Review />
      </div>
    </>
  );
};

export default HomePage;
