import React, { useState, useEffect } from "react";
import axios from "axios";
import { countries, states as allStates } from "../../js/country-state"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";

const DeviceActivation = () => {
  const navigate = useNavigate(); // Corrected useNavigate() usage
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    imeiNumber: "",
    simNumber: "", // New field
    vehicleNumber: "",
    vehicleType: "",  
    vehicleOverspeed: "", // New field
    vehicleMileage: "", // New field for Vehicle Mileage
    language: "", // New field for Language
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formData.country) {
      setStatesList(allStates[formData.country] || []);
    } else {
      setStatesList([]);
    }
  }, [formData.country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Password match validation
    if (name === "confirmPassword" || name === "password") {
      if (name === "confirmPassword" && value !== formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Passwords do not match.",
        }));
      } else if (name === "password" && value !== formData.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Passwords do not match.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.username) newErrors.username = "Username is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    if (!formData.confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required.";
    if (!formData.email) {
      newErrors.email = "Email Address is required.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.phone) newErrors.phone = "Phone Number is required.";
    if (!formData.simNumber) newErrors.simNumber = "Sim Number is required.";
    if (!formData.vehicleOverspeed)
      newErrors.vehicleOverspeed = "Vehicle Overspeed is required.";
    if (!formData.language) newErrors.language = "Language is required.";
    if (!formData.imeiNumber) newErrors.imeiNumber = "IMEI Number is required.";
    if (!formData.companyName)
      newErrors.companyName = "Company Name is required.";
    if (!formData.vehicleNumber)
      newErrors.vehicleNumber = "Vehicle Number is required.";
    if (!formData.vehicleMileage)
      newErrors.vehicleMileage = "Vehicle Mileage is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.vehicleType)
      newErrors.vehicleType = "Vehicle Type is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setStatus("Please fill out all required fields correctly.");
      setAlertClass("alert alert-danger d-block mt-4");
      return;
    }

    axios
      .post("http://findpath.co.in/Backend/device_activation.php", formData)
      .then((response) => {
        if (response.data.success) {
          setStatus(
            "Success! Thank you for submitting the form. We will get back to you soon."
          );
          setAlertClass("alert alert-success d-block mt-4");
          setFormData({
            username: "",
            password: "",
            confirmPassword: "",
            companyName: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            country: "",
            imeiNumber: "",
            vehicleNumber: "",
            vehicleType: "",
            message: "",
            simNumber: "", // New field
            vehicleOverspeed: "", // New field
            language: "", // New field for Language
            vehicleMileage: "", // New field for Vehicle Mileage
          });
          navigate("/thank-you"); // Navigate to the thank you page
        } else {
          setStatus(`Error! ${response.data.message}`);
          setAlertClass("alert alert-danger d-block mt-4");
        }
      })
      .catch((error) => {
        setStatus(`Outer Error! ${error.message}`);
        setAlertClass("alert alert-danger d-block mt-4");
      });
  };

  const inputStyle = { backgroundColor: "#e3eff9" }; // Inline style for background color

  return (
    <div className="container py-5">
      <h2 className="text-center font-weight-bold mb-4">GPS Activation</h2>
      <div className="row justify-content-center">
        <div className="col-lg-10 col-xl-8">
          <form
            method="POST"
            className="contact-form custom-form-style-1 border border-primary p-4 rounded"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="_subject" value="New demo request!" />
            <input type="hidden" name="_next" />
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_honeypot" value="" />

            <div className={alertClass} role="alert">
              {status}
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Username</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.username ? "is-invalid" : ""
                  }`}
                  name="username"
                  value={formData.username}
                  onChange={(e) => {
                    // Keep only alphabetic characters and convert to lowercase
                    const value = e.target.value
                      .replace(/[^a-zA-Z]/g, "")
                      .toLowerCase();
                    handleChange({
                      target: {
                        name: "username",
                        value: value,
                      },
                    });
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Password</label>
                <input
                  type="password"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Company Name</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.companyName && (
                  <div className="invalid-feedback">{errors.companyName}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Email Address</label>
                <input
                  type="email"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Phone Number</label>
                <input
                  type="tel"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.phone ? "is-invalid" : ""
                  }`}
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and ensure the length does not exceed 10
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  style={inputStyle}
                />
                {errors.phone && (
                  <div className="invalid-feedback">{errors.phone}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Country</label>
                <select
                  name="country"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.country ? "is-invalid" : ""
                  }`}
                  value={formData.country}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} {country.dialCode}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <div className="invalid-feedback">{errors.country}</div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">State</label>
                <select
                  name="state"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.state ? "is-invalid" : ""
                  }`}
                  value={formData.state}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select State</option>
                  {statesList.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">City</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.city ? "is-invalid" : ""
                  }`}
                  name="city"
                  value={formData.city}
                  onChange={(e) => {
                    // Keep only alphabetic characters and convert to lowercase
                    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                    handleChange({
                      target: {
                        name: "city",
                        value: value,
                      },
                    });
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.city && (
                  <div className="invalid-feedback">{errors.city}</div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">IMEI Number</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.imeiNumber ? "is-invalid" : ""
                  }`}
                  name="imeiNumber"
                  value={formData.imeiNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.imeiNumber && (
                  <div className="invalid-feedback">{errors.imeiNumber}</div>
                )}
              </div>
            </div>
            <div className="row">
              {/* New field: Sim Number */}
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Sim Number</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.simNumber ? "is-invalid" : ""
                  }`}
                  name="simNumber"
                  value={formData.simNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.simNumber && (
                  <div className="invalid-feedback">{errors.simNumber}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Vehicle Number</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.vehicleNumber ? "is-invalid" : ""
                  }`}
                  name="vehicleNumber"
                  value={formData.vehicleNumber}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.vehicleNumber && (
                  <div className="invalid-feedback">{errors.vehicleNumber}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Vehicle Type</label>
                <select
                  className={`form-control text-3 h-auto py-2 ${
                    errors.vehicleType ? "is-invalid" : ""
                  }`}
                  name="vehicleType"
                  value={formData.vehicleType}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="Ambulance">Ambulance</option>
                  <option value="Asset">Asset</option>
                  <option value="Bicycle">Bicycle</option>
                  <option value="Boat">Boat</option>
                  <option value="Bulldozer">Bulldozer</option>
                  <option value="Crane">Crane</option>
                  <option value="Dumper">Dumper</option>
                  <option value="Forklift">Forklift</option>
                  <option value="Garbage">Garbage</option>
                  <option value="JCB">JCB</option>
                  <option value="Machinery">Machinery</option>
                  <option value="Mixer">Mixer</option>
                  <option value="Person">Person</option>
                  <option value="Pet">Pet</option>
                  <option value="Pickup">Pickup</option>
                  <option value="Public Bus">Public Bus</option>
                  <option value="Road Roller">Road Roller</option>
                  <option value="Scooty">Scooty</option>
                  <option value="School Bus">School Bus</option>
                  <option value="Service Van">Service Van</option>
                  <option value="SUV">SUV</option>
                  <option value="Tanker">Tanker</option>
                  <option value="Tempo">Tempo</option>
                  <option value="Tractor">Tractor</option>
                  <option value="Trailer">Trailer</option>
                  <option value="Truck">Truck</option>
                  <option value="3 Wheeler">3 Wheeler</option>
                </select>
                {errors.vehicleType && (
                  <div className="invalid-feedback">{errors.vehicleType}</div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">
                  Vehicle Overspeed
                </label>
                <select
                  className={`form-control text-3 h-auto py-2 ${
                    errors.vehicleOverspeed ? "is-invalid" : ""
                  }`}
                  name="vehicleOverspeed"
                  value={formData.vehicleOverspeed}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Vehicle Overspeed</option>
                  <option value="40">20</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                  <option value="110">110</option>
                  <option value="120">120</option>
                  <option value="130">130</option>
                  <option value="140">140</option>
                  <option value="150">150</option>
                  <option value="160">160</option>
                  <option value="170">170</option>
                  <option value="180">180</option>
                  <option value="180">190</option>
                  <option value="180">200</option>
                  <option value="180">210</option>
                  <option value="180">220</option>
                </select>
                {errors.vehicleOverspeed && (
                  <div className="invalid-feedback">
                    {errors.vehicleOverspeed}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">
                  Vehicle Mileage
                </label>
                <select
                  className={`form-control text-3 h-auto py-2 ${
                    errors.vehicleMileage ? "is-invalid" : ""
                  }`}
                  name="vehicleMileage"
                  value={formData.vehicleMileage}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Vehicle Mileage</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                  <option value="15">15</option>
                  <option value="18">18</option>
                  <option value="20">20</option>
                  <option value="23">23</option>
                  <option value="25">25</option>
                  <option value="28">28</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                  <option value="60">60</option>
                  <option value="65">65</option>
                  <option value="70">70</option>
                  <option value="75">75</option>
                  <option value="80">80</option>
                  <option value="85">85</option>
                  <option value="90">90</option>
                  <option value="95">95</option>
                  <option value="100">100</option>
                </select>
                {errors.vehicleMileage && (
                  <div className="invalid-feedback">
                    {errors.vehicleMileage}
                  </div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className={`form-label mb-1 text-2 ${
                    errors.language ? "is-invalid" : ""
                  }`}>Language</label>
                <select
                  className="form-control text-3 h-auto py-2"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Language</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Arabic">Arabic</option>
                  <option value="French">French</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Gujarati">Gujarati</option>
                </select>
                {errors.language && (
                  <div className="invalid-feedback">{errors.language}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label mb-1 text-2">Message</label>
                <textarea
                  className={"form-control text-3 h-auto py-2"}
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="form-group col text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-modern font-weight-bold custom-btn-border-radius custom-btn-arrow-effect-1 text-3 px-5 py-3"
                  data-loading-text="Loading..."
                >
                  Submit
                  <svg
                    className="ms-2"
                    version="1.1"
                    viewBox="0 0 15.698 8.706"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      stroke="#FFF"
                      strokeWidth="0.1"
                      fill="#FFF"
                      points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeviceActivation;
