import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../Assests/logo.png";
import iconCartBig from "../../Assests/icon-cart-big.svg";
import s2 from "../../Assests/s2.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import DailyOffers from "../../Components/DailyOffers/DailyOffers";

//custome css
import "./NavBar.css";

const NavBar = () => {
  //For serach Bar
  // const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const toggleSearch = () => {
  //   setIsSearchOpen(!isSearchOpen);
  // };
  //for add to cart
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };
  //for navigation to home page
  const navigate = useNavigate();

  const [isNoticeVisible, setIsNoticeVisible] = useState(true);
  const handleClose = () => {
    setIsNoticeVisible(false);
  };
  const [quantity, setQuantity] = useState(1); // State for quantity
  const pricePerItem = 20000; // Price per item
  const [totalPrice, setTotalPrice] = useState(pricePerItem); // State for total price

  const increaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    setTotalPrice(newQuantity * pricePerItem);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      setTotalPrice(newQuantity * pricePerItem);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    setDropdownOpen(false); // Close the dropdown after navigation
  };
  // useEffect(() => {
  //   const [isSearchOpen, setIsSearchOpen] = useState(false);

  //   const toggleSearch = () => {
  //     setIsSearchOpen(!isSearchOpen);
  //   };
  // Load Google Analytics script
  // const script = document.createElement("script");

  //   const keyframes = `
  //   @keyframes shake {
  //     0% { transform: translateX(0); }
  //     10%, 90% { transform: translateX(-1px); }
  //     20%, 80% { transform: translateX(2px); }
  //     30%, 50%, 70% { transform: translateX(-4px); }
  //     40%, 60% { transform: translateX(4px); }
  //     100% { transform: translateX(0); }
  //   }
  // `;
  // const styleSheet = document.styleSheets[0];
  // styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

  //   script.src = "https://www.googletagmanager.com/gtag/js?id=UA-42715764-11";
  //   script.async = true;
  //   document.head.appendChild(script);

  //   // Initialize Google Analytics
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag("js", new Date());
  //   gtag("config", "UA-42715764-11");

  //   // Cleanup function
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  // const iconStyle = {
  //   animation: 'shake 0.1s infinite',
  // };

  return (
    <div className="body" style={{ transition: "margin 300ms ease 0s" }}>
      <header
        id="header"
        className="fixed-header"
        // {
        //   // `${!isNoticeVisible ? 'shift-up' : ''}`
        // }
        dataPluginOptions={{
          stickyEnabled: true,
          stickyEnableOnBoxed: true,
          stickyEnableOnMobile: false,
          stickyStartAt: 54,
          stickySetTop: "-54px",
          stickyChangeLogo: false,
        }}
      >
        <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
          <DailyOffers />
          {/* Header content starts */}
          <div className="header-top header-top-small-minheight header-top-simple-border-bottom">
            <div className="container py-2">
              <div className="header-row justify-content-between">
                <div className="header-column col-auto px-0">
                  <div className="header-row">
                    <div className="header-nav-top">
                      <ul className="nav nav-pills position-relative">
                        <li className="nav-item d-none d-sm-block">
                          <span className="d-flex align-items-center font-weight-medium ws-nowrap text-3 ps-0">
                            <a
                              href="mailto:porto@domain.com"
                              className="text-decoration-none text-color-dark text-color-hover-primary"
                            >
                              <i className="icons icon-envelope font-weight-bold position-relative text-4 top-3 me-1"></i>
                              support@findpath.co.in
                            </a>
                          </span>
                        </li>
                        <li className="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-sm-show">
                          <a
                            role="button"
                            href="+91 9958799582"
                            className="d-flex align-items-center font-weight-medium text-color-dark ws-nowrap text-3 text-color-hover-primary text-decoration-none"
                          >
                            <i className="icons icon-phone font-weight-bold position-relative text-3 top-1 me-2"></i>
                            +91 9958799582
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="header-column justify-content-end col-auto px-0 d-none d-md-flex">
                  <div className="header-row">
                    <nav className="header-nav-top">
                      <ul className="header-social-icons social-icons social-icons-clean social-icons-icon-gray social-icons-medium custom-social-icons-divider">
                        <li className="social-icons-facebook">
                          <a
                            role="button"
                            href="https://www.facebook.com/people/Findpath/61557578795914/"
                            target="_blank"
                            title="Facebook"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="twitter-hover">
                          <a
                            className="twitter-hover"
                            role="button"
                            href="https://x.com/i/flow/login?redirect_after_login=%2Ffindpathgps"
                            target="_blank"
                            title="Twitter"
                          >
                            <i className="fab fa-x-twitter"></i>
                          </a>
                        </li>
                        <li className="social-icons-linkedin">
                          <a
                            role="button"
                            href="https://www.linkedin.com/company/findpath-co-in/"
                            target="_blank"
                            title="Linkedin"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            role="button"
                            href="https://www.instagram.com/findpath.co.in/"
                            target="_blank"
                            className="instagram-hover"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            role="button"
                            href="https://www.youtube.com/channel/UCMXaS-xGApsGs4DCWRxEO5g"
                            target="_blank"
                            className="youtube-hover"
                          >
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-container container ">
            <div className="header-row">
              <div className="header-column w-100">
                <div className="header-row justify-content-between">
                  <div className="header-logo z-index-6 col-lg-2 px-0">
                    <a role="button" onClick={() => navigate("/")}>
                      <img
                        alt="Porto"
                        width="180"
                        height="130"
                        data-sticky-width="82"
                        data-sticky-height="40"
                        data-sticky-top="84"
                        src={logo}
                      />
                    </a>
                  </div>
                  <div className="header-nav header-nav-links justify-content-end ">
                    <div className="header-nav-main header-nav-main-arrows header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1 ">
                      <nav className="collapse">
                        <ul className="nav nav-pills" id="mainNav">
                          <li className="dropdown">
                            <a
                              role="button"
                              className="dropdown-item cursor-po"
                              onClick={() => navigate("/")}
                            >
                              Home
                            </a>
                          </li>
                          <li>
                            <a
                              role="button"
                              className="dropdown-item"
                              onClick={() => navigate("/aboutus")}
                            >
                              About us
                            </a>
                          </li>
                          <li
                            className="dropdown"
                            onMouseEnter={() => setDropdownOpen(true)}
                            onMouseLeave={() => setDropdownOpen(false)}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              onClick={() => {
                                if (window.innerWidth <= 586) {
                                  navigate("/all-products"); // Navigate to mobile product page
                                }
                              }}
                            >
                              Products
                            </a>
                            {dropdownOpen && (
                              <ul className="dropdown-menu show">
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate("/product/findpath-lite")
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Lite
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate("/product/findpath-pro")
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Pro
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate("/product/findpath-go")
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Go
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate(
                                        "/product/findpath-go-plus"
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Go Plus
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate("/product/findpath-e-lock")
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath E-Lock
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate(
                                        "/product/findpath-dashcams"
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Dashcams
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="button"
                                    onClick={() =>
                                      handleNavigate(
                                        "/product/findpath-fuel-sensor"
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    Findpath Fuel Sensor
                                  </a>
                                </li>
                              </ul>
                            )}
                          </li>
                          <li className="dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              onClick={() => {
                                if (window.innerWidth <= 586) {
                                  navigate("/all-solutions"); // Navigate to mobile product page
                                }
                              }}
                            >
                              Solution
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  role="button"
                                  onClick={() =>
                                    handleNavigate("/solution/e-lock_solution")
                                  }
                                  className="dropdown-item"
                                >
                                  E-Lock Solution
                                </a>
                              </li>
                              <li>
                                <a
                                  role="button"
                                  onClick={() =>
                                    handleNavigate(
                                      "/solution/route-planning-solution"
                                    )
                                  }
                                  className="dropdown-item"
                                >
                                  Route Planning Solution
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" role="button"
                                  onClick={() =>
                                    handleNavigate(
                                      "/solution/fuel-monitoring-solution"
                                    )
                                  }>
                                  Fuel Monitoring Solution
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" role="button" onClick={() =>
                                    handleNavigate( 
                                      "/solution/drive-behaviour-solution"
                                    )
                                  }>
                                  Driver Behaviour Solution
                                </a>
                              </li>

                              <li>
                                <a className="dropdown-item" role="button" onClick={() =>
                                    handleNavigate(
                                      "/solution/video-telematics-solution"
                                    )
                                  }>
                                  Video Telematics Solution
                                </a>
                              </li>

                              <li>
                                <a className="dropdown-item" role="button" onClick={() =>
                                    handleNavigate(
                                      "/solution/fleet-management-solution"
                                    )
                                  }>
                                  Fleet Management Solution
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" role="button" onClick={() =>
                                    handleNavigate(
                                      "/solution/electric-vehicles-management"
                                    )
                                  }>
                                  Electric Vehicles Management
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              onClick={() => {
                                if (window.innerWidth <= 586) {
                                  navigate("/all-Industry"); // Navigate to mobile product page
                                }
                              }}
                            >
                              Industry
                            </a>
                            <ul className="dropdown-menu">
                            
                             
                            
                            
                             
                            
                             
                              <li>
                                <a className="dropdown-item">FMCG</a>
                              </li>
                              <li>
                                <a className="dropdown-item">Agriculture</a>
                              </li>
                              <li>
                                <a className="dropdown-item">Rental Vehicles</a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  Public Transport
                                </a>
                              </li>
                              <li>
                                <a
                                  role="button"
                                  onClick={() => handleNavigate("/industry")}
                                  className="dropdown-item"
                                >
                                  Logistics & Transport
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  Waste Management
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  Medical & Healthcare
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  Construction & Mining
                                </a>
                              </li>
                            
                            </ul>
                          </li>
                          {/* <li className="dropdown">
                            <a className="nav-link dropdown-toggle">
                              Resources
                            </a>
                            <ul className="dropdown-menu">
                              
                              
                              <li className="mb-2">
                <a className="">Glossary</a>
              </li>
                              <li>
                                <a className="dropdown-item">Case Studies</a>
                              </li>
                            
                            
                            </ul>
                          </li> */}
                          <li className="dropdown">
                            <a className="nav-link dropdown-toggle">Company </a>
                            <ul className="dropdown-menu">
                              <li className="dropdown">
                                <a role="button" className="nav-link ">
                                  Media
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">Events</a>
                              </li>
                              <li>
                                <a className="dropdown-item">Careers</a>
                              </li>

                              <li>
                                <a
                                  role="button"
                                  onClick={() => navigate("/contactus")}
                                  className="dropdown-item"
                                >
                                  Contact Us
                                </a>
                              </li>
                              <li>
                                <a
                                  role="button"
                                  onClick={() => navigate("/what's-new")}
                                  className="dropdown-item"
                                >
                                  What’s New
                                </a>
                              </li>
                              <li>
                                <Link
                                  to="/partnerwithus"
                                  className="dropdown-item"
                                >
                                  Partner with us
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <ul className="header-extra-info custom-left-border-1 d-none d-xl-block">
                    <li className="d-none d-sm-inline-flex m-0">
                      {/* <div className="col-lg-5 text-center text-lg-start ps-lg-4 custom-btn-responsive">
                        <Link
                          to="/getademo"
                          className="btn btn-primary btn-outline custom-btn-border-radius font-weight-bold text-2 ms-lg-2 custom-btn-responsive"
                          style={{
                            opacity: "1",
                            width: "100px",
                            padding: "6px",
                          }}
                        >
                          Get A Demo
                        </Link>
                     
                      </div> */}
                      <form
                        action="https://www.okler.net/previews/porto/10.2.0/page-search-results.html"
                        method="get"
                      >
                        <div className="input-group mb-3 pb-1">
                          <input
                            className="form-control text-1"
                            placeholder="GPS Tracke..."
                            name="search"
                            id="search"
                            type="text"
                            style={{ position: "relative", top: "12px" }}
                          />
                          <button
                            type="submit"
                            className="btn btn-dark text-1 p-2 btn-hover"
                            style={{
                              height: "35px",
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                              top: "12px",
                              backgroundColor: "white",
                            }}
                          >
                            <i
                              className="fas fa-search m-2"
                              style={{ color: "black" }}
                            ></i>
                          </button>
                        </div>
                      </form>
                    </li>
                  </ul>
                  <div className="d-flex col-auto pe-0 ps-0 ps-xl-3">
                    <div className="header-nav-features ps-0 ms-1">
                      <div className="header-nav-feature header-nav-features-search d-inline-flex position-relative top-9 border border-top-0 border-bottom-0 custom-remove-mobile-border-left px-4 me-2">
                        {/* <a
                        href="#"
                        className="header-nav-features-toggle text-decoration-none"
                        data-focus="headerSearch"
                        aria-label="Search"
                        onClick={toggleSearch}
                      > */}
                        <div>
                          {/* <a
                            role="button"
                            href="#"
                            className="text-decoration-none text-color-dark text-color-hover-primary text-6 pt-1"
                            onClick={() => navigate("/login")}
                          >
                            <i className="icons icon-user "></i>
                          </a> */}
                          <a
                            role="button"
                            className="header-nav-features-toggle cart-hover"
                            aria-label="User Login"
                            onClick={() => navigate("/login")}
                          >
                            <i
                              className="bi bi-person"
                              style={{ fontSize: "31px" }}
                            ></i>
                          </a>
                        </div>

                        {/* </a> */}
                        {/* <div
                        className={`header-nav-features-dropdown header-nav-features-dropdown-mobile-fixed border-radius-0 ${
                          isSearchOpen ? "show" : ""
                        }`}
                        id="headerTopSearchDropdown"
                      >
                        <form
                          role="search"
                          action="https://www.okler.net/previews/porto/10.2.0/page-search-results.html"
                          method="get"
                        >
                          <div className="simple-search input-group">
                            <input
                              className="form-control text-1 rounded"
                              id="headerSearch"
                              name="q"
                              type="search"
                              value=""
                              placeholder="Search..."
                            />
                            <button
                              className="btn"
                              type="submit"
                              aria-label="Search"
                            >
                              <i className="icons icon-magnifier header-nav-top-icon"></i>
                            </button>
                          </div>
                        </form>
                      </div> */}
                      </div>
                      <div className="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex top-2 ms-2">
                        <a
                          role="button"
                          className="header-nav-features-toggle cart-hover bag-icon"
                          aria-label="Add to Cart"
                          onClick={toggleCart}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="black"
                            className="bi bi-bag header-nav-top-icon-img"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                          </svg>
                        </a>
                        <div
                          className={`header-nav-features-dropdown ${
                            isCartOpen ? "show" : ""
                          }`}
                          id="headerTopCartDropdown"
                        >
                          <div className="header-cart">
                            <h4>Your Cart</h4>
                            <button className="close-btn" onClick={toggleCart}>
                              ×
                            </button>
                          </div>
                          <ol className="mini-products-list">
                            <li className="item">
                              <a
                                title="Product Short Name"
                                className="product-image"
                              >
                                <img src={s2} alt="Product Short Name" />
                              </a>
                              <div className="product-details">
                                <p className="product-name">
                                  <a role="button">Product Short Name</a>
                                </p>
                                <p className="qty-price">
                                  {quantity}X{" "}
                                  <span className="price">${pricePerItem}</span>
                                </p>
                                <div className="quantity-controls">
                                  <button
                                    className="quantity-btn"
                                    onClick={decreaseQuantity}
                                  >
                                    -
                                  </button>
                                  <span className="quantity-text">
                                    {quantity}
                                  </span>
                                  <button
                                    className="quantity-btn"
                                    onClick={increaseQuantity}
                                  >
                                    +
                                  </button>
                                </div>
                                <a
                                  title="Remove This Item"
                                  className="btn-remove"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                  {/* Replace with trash icon */}
                                </a>
                              </div>
                            </li>
                          </ol>
                          <div className="totals">
                            <span className="label">Total:</span>
                            <span className="price-total">
                              <span className="price">${totalPrice}</span>
                            </span>
                          </div>
                          <div className="actions">
                            <a role="button" className="btn btn-dark">
                              View Cart
                            </a>
                            <a role="button" className="btn btn-primary">
                              Checkout
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn header-btn-collapse-nav ms-4"
                    data-bs-toggle="collapse"
                    data-bs-target=".header-nav-main nav"
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
