import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Card, Row, Col } from 'react-bootstrap';

const Testimonials = () => {
  const testimonials = [
    { text: 'Best quality product at reasonable price. After sale service is too good. 👍', author: 'Megha Modi' },
    { text: 'Best GPS Tracker Company in Rohini Delhi.', author: 'Swami Saab' },
    { text: 'Best company..Best products & Best service. 👌🏻', author: 'PINKY SHARMA' },
    { text: 'Premium Quality Product & Good Service Support.', author: 'Shreya Gupta' },
    { text: 'Excellent service support and very useful products.', author: 'syed kasif ali' },
    { text: 'Excellent service and products. Come and experience it.', author: 'Rajat Garg' },
    { text: 'Findpath gps exceeded my expectations with its accurate and reliable navigation.The battery life is impressive and  The voice-guided directions are clear.', author: 'The Barbeque Project' },
    { text: 'Superb quality product with reasonable price and best services.', author: 'Radha Modi' },
    { text: 'Great quality products with commendable services.', author: 'Palak Mittal' },
    { text: 'Best company with best products and services.', author: 'Moni Devi' },
    { text: 'Findpath Provide Best GPS Tracker in Rohini.', author: '2USAHIL' },
    { text: 'Best GPS Tracker Company in Rohini Delhi.', author: 'Swami Saab' },
  ];

  return (
    <section className="section border-0 m-0 theme-color-matching">
      <div className="container pb-3 my-5">
        <div className="row justify-content-center pb-3 mb-4">
          <div className="col text-center">
            <h2 className="font-weight-bold text-color-dark line-height-1 mb-0">
              See What Clients Are Saying
            </h2>
            <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
              <hr className="my-0" />
            </div>
            <p className="font-weight-bold text-3-5 mb-1">
              We are very proud of the services we provide and stand by every product we carry.
            </p>
            <p className="font-weight-light text-3-5 mb-0">
              Read our testimonials from our happy customers.
            </p>
          </div>
        </div>
        <Carousel indicators={false} controls={true} interval={3000} pause={false}>
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6} className="mb-3">
                  <Card className="custom-border-radius-1 h-100">
                    <Card.Body>
                      <div className="custom-testimonial-style-1 testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote text-center mb-0">
                        <blockquote>
                          <p className="text-color-dark text-3 font-weight-light px-0 mb-2">
                            {testimonial.text}
                          </p>
                        </blockquote>
                        <div className="testimonial-author">
                          <p><strong className="font-weight-extra-bold">{testimonial.author}</strong></p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;
