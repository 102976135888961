import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const FAQsHeader = () => {
    const navigate = useNavigate();

    return (
      <section
        className="page-header page-header-modern page-header-lg"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">FAQs</h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active">FAQs</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const FAQs = () => {
    useEffect(() => {
        // Scroll to top on initial load
        window.scrollTo(0, 0);
      }, []);
    
      return (
        <section className="tremspolicy_wrapper">
          <FAQsHeader/>
          <div className="container">
            <div className="tremspolicy_content">
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className="mb-3 mt-3">What is the purpose of a privacy policy?</h3>
                <p className="f16 mb20">
                A privacy policy outlines how a company collects, uses, and protects user data, ensuring transparency and building trust with users.
                </p>
              </div>
    
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className=" mb-3 mt-3">How often should a privacy policy be updated?</h3>
                <p className="f16">
                A privacy policy should be reviewed and updated regularly, especially when there are changes in data practices or relevant laws.
                  <br />
                </p>
              </div>
    
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className="mb-3 mt-3">Can a business use a generic privacy policy template?</h3>
               
                <p className="f16">
                While templates can be a good starting point, it's essential to customize your privacy policy to reflect your specific data practices and legal obligations.
                </p>
              </div>
    
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className="mb-3 mt-3">What happens if a company violates its privacy policy?</h3>
                <p className="f16 mb20">
                Violating a privacy policy can lead to legal penalties, loss of user trust, and potential lawsuits.
                </p>
              </div>
    
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className="mb-3 mt-3">How can users protect their personal information?</h3>
              
                <p className="f16 mb20">
                Users can protect their personal information by being cautious about the data they share, using strong passwords, and regularly reviewing privacy settings on websites and apps.
                </p>
               
              </div>
    
              <div className="termspolicy_content__wrapper clearfix">
                <h3 className="mb-3 mt-3">Contact Us</h3>
                <p className="f16 mb20">
                  If you have any questions or concerns about this FAQs, please contact us at:{" "}
                  <a role="button" className="text-color-prime">
                    support@findpath.co.in
                  </a>
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
      );
  }

export default FAQs
