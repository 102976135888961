import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const PrivacyPolicyHeader = () => {
  const navigate = useNavigate();

  return (
    <section
      className="page-header page-header-modern page-header-lg"
      style={{
        backgroundColor: "#e3eff9",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 className="text-color-dark font-weight-bold">Our Company</h1>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
              <li>
                <a
                  role="button"
                  onClick={() => navigate("/")}
                  className="text-color-default text-color-hover-primary text-decoration-none"
                >
                  HOME
                </a>
              </li>
              <li className="active">Our Company</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
const OurCompany = () => {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <PrivacyPolicyHeader />
      <div className="container">
        <div className="tremspolicy_content">
          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              What is the purpose of a privacy policy?
            </h3>
            <p className="f16 mb20">
              Findpath works by using advanced satellite technology to provide
              real-time location tracking. Once installed in a vehicle or
              carried by an individual, the device transmits precise location
              data to a secure server. Users can access this information via an
              intuitive app or web platform, enabling them to monitor movement,
              set geofences, and receive alerts for specific events, ensuring
              safety and efficient Tracking. Protect your belongings with the
              advanced Gps Tracker by Findpath. Our innovative technology helps
              you track and locate your stolen or lost items, giving you peace
              of mind.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className=" mb-3 mt-3">Our Speciality</h3>
            <p className="f16">
              Findpath stands out with its advanced satellite technology,
              offering real-time, precise location tracking. Its user-friendly
              interface ensures easy setup and operation, making it accessible
              for everyone. Key features include geofencing capabilities,
              allowing users to set virtual boundaries and receive alerts, and
              custom notifications for specific events like speeding or
              unauthorized movements.
              <br />
            </p>
            <p>
              Findpath is versatile, ideal for personal safety, vehicle
              tracking, and efficient fleet management. Its robust data security
              protocols ensure that user information is always protected, making
              it a reliable and comprehensive tracking solution.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              Difference Between Others and Our Product
            </h3>
            <p className="f16">
              Findpath stands out with its state-of-the-art satellite
              technology, ensuring unmatched accuracy and reliability. Unlike
              other products that may offer limited features, Findpath GPS
              provides comprehensive real-time tracking, geofencing, and custom
              alerts. Its user-friendly interface simplifies navigation and
              setup, making it accessible to all users.
              <br />
            </p>
            <p>
              Findpath prioritizes data security with robust encryption,
              ensuring user privacy. While other products might skimp on these
              aspects, Findpath delivers a superior, all-in-one solution for
              personal safety, vehicle tracking, and efficient fleet management,
              setting a new standard in the GPS tracking industry.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              What happens if a company violates its privacy policy?
            </h3>
            <p className="f16 mb20">
              Violating a privacy policy can lead to legal penalties, loss of
              user trust, and potential lawsuits.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              How can users protect their personal information?
            </h3>

            <p className="f16 mb20">
              Users can protect their personal information by being cautious
              about the data they share, using strong passwords, and regularly
              reviewing privacy settings on websites and apps.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Contact Us</h3>
            <p className="f16 mb20">
              If you have any questions or concerns about this ourcompany, please
              contact us at:{" "}
              <a role="button" className="text-color-prime">
                support@findpath.co.in
              </a>
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCompany;
