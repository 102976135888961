import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const RoutePlanningSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
              Route Planning Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Route Planning Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Efficient Routes</h4>
              <p className="mb-0">
                "Efficient Routes" with Findpath Route Planning Solution
                optimises travel by calculating the quickest and most
                cost-effective paths. This feature minimises travel time and
                fuel consumption, ensuring that you reach your destination
                faster and more economically. By analysing real-time data and
                traffic conditions, Findpath provides routes that streamline
                your journey, enhancing overall efficiency and saving valuable
                resources.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Real-Time Navigation</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                "Real-Time Navigation" with Findpath Route Planning Solution
                ensures you receive up-to-the-minute directions and traffic
                updates. This feature helps you navigate accurately and adapt to
                changing conditions on the go. By offering live route
                adjustments and current traffic information, Findpath enhances
                your travel experience, keeping you informed and on track for a
                smoother, more efficient journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Accurate Mapping</h4>
                  <p className="mb-0">
                    "Accurate Mapping" with Findpath Route Planning Solution
                    delivers precise and detailed maps to guide your journey.
                    This feature ensures that you have reliable visual
                    references for your route, minimising the chances of errors
                    or detours. By providing clear and exact geographical data,
                    Findpath helps you navigate confidently, making your travel
                    experience more straightforward and dependable.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Optimised Travel</h4>
                    <p className="mb-0">
                      "Optimised Travel" with Findpath Route Planning Solution
                      enhances your journey by finding the most efficient
                      routes. This feature reduces travel time and costs by
                      considering factors such as traffic conditions, road
                      closures, and distance. By providing the best travel
                      options, Findpath ensures that you reach your destination
                      quickly and economically, maximising your overall travel
                      efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Seamless Integration</h4>
                    <p className="mb-0">
                      "Seamless Integration" with Findpath Route Planning
                      Solution ensures that the system effortlessly connects
                      with your existing tools and platforms. This feature
                      allows for smooth synchronisation with GPS devices, mobile
                      apps, and other navigation aids, providing a cohesive and
                      efficient user experience. Findpath streamlines the
                      integration process, enhancing functionality and ensuring
                      that route planning is as intuitive and effective as
                      possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Route Optimization</h4>
                  <p className="mb-5">
                    "Route Optimization" with Findpath Route Planning Solution
                    fine-tunes your travel paths for maximum efficiency. This
                    feature analyses various factors like traffic patterns, road
                    conditions, and distance to determine the best route. By
                    selecting the most efficient paths, Findpath reduces travel
                    time, minimises fuel consumption, and enhances overall
                    journey efficiency, ensuring you reach your destination in
                    the most effective manner.
                  </p>
                  <h4 className="mb-3">Smart Directions</h4>
                  <p className="mb-0">
                    "Smart Directions" with Findpath Route Planning Solution
                    provides intelligent and adaptive navigation guidance. This
                    feature delivers dynamic route suggestions based on
                    real-time data, such as traffic conditions and road
                    closures. By offering precise and context-aware directions,
                    Findpath ensures a smoother, more efficient journey, helping
                    you navigate with confidence and adapt to any changes in
                    your travel environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoutePlanningSolution;
