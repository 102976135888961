import React from "react";
import LeftSideBar from "../../Components/LeftSideBar/LeftSideBar";
import RightSideBar from "../../Components/RightSideBar/RightSideBar";
import MiddleImgBar from "../../Components/MiddleImgBar/MiddleImgBar";
import RelatedProducts from "../../Components/RelatedProduct/RelatedProduct";

import image1 from "../../Assests/Product/FindpathGoplus/product1.jpg";
import image2 from "../../Assests/Product/FindpathGoplus/product2.jpg";
import image3 from "../../Assests/Product/FindpathGoplus/product3.jpg";
import image4 from "../../Assests/Product/FindpathGoplus/product4.jpg";
import image5 from "../../Assests/Product/FindpathGoplus/product5.jpg";

const images = [image1, image2, image3, image4, image5];

const FindpathLiteAddToCart = () => {
  const productData = {
    productName: "Findpath Go Plus",
    reviews: 37,
    salePrice: 6999.0,
    originalPrice: 13998.0,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus nibh sed elimttis adipiscing. Fusce in hendrerit purus. Lorem ipsum dolor sit amet.",
    availability: "AVAILABLE",
    sku: "1234567890",
  };

  // Description component
  const DescriptionTab = () => (
    <div
      className="tab-pane px-0 py-3 active"
      id="productDescription"
      role="tabpanel"
    >
      <p>
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia Curae; Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Fusce sagittis, massa fringilla consequat blandit, mauris ligula porta
        nisi, non tristique enim sapien vel nisl. Suspendisse vestibulum
        lobortis dapibus.{" "}
      </p>
      <p className="m-0">
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia Curae; Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Fusce sagittis, massa fringilla consequat blandit, mauris ligula porta
        nisi, non tristique enim sapien vel nisl. Suspendisse vestibulum
        lobortis dapibus. Vestibulum ante ipsum primis in faucibus orci luctus
        et ultrices posuere cubilia Curae;
      </p>
    </div>
  );

  // Additional Information component
  const AdditionalInfoTab = () => (
    <div className="tab-pane px-0 py-3" id="productInfo" role="tabpanel">
      <table className="table table-striped m-0">
        <tbody>
          <tr>
            <th className="border-top-0">Size:</th>
            <td className="border-top-0">Unique</td>
          </tr>
          <tr>
            <th>Colors</th>
            <td>Red, Blue</td>
          </tr>
          <tr>
            <th>Material</th>
            <td>100% Leather</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  // Reviews component
  const ReviewsTab = () => (
    <div className="tab-pane px-0 py-3" id="productReviews" role="tabpanel">
      <ul className="comments">
        <li>
          <div className="comment">
            <div className="img-thumbnail border-0 p-0 d-none d-md-block">
              <img
                className="avatar rounded-circle"
                alt=""
                src="img/avatars/avatar-2.jpg"
              />
            </div>
            <div className="comment-block">
              <div className="comment-arrow"></div>
              <span className="comment-by">
                <strong>Jack Doe</strong>
                <span className="float-end">
                  <div className="pb-0 clearfix">
                    <div title="Rated 3 out of 5" className="float-start">
                      <input
                        type="text"
                        className="d-none"
                        value="3"
                        title=""
                        data-plugin-star-rating=""
                        data-plugin-options="{'displayOnly': true, 'color': 'primary', 'size':'xs'}"
                      />
                    </div>
                    <div className="review-num">
                      <span className="count" itemprop="ratingCount">
                        2
                      </span>{" "}
                      reviews
                    </div>
                  </div>
                </span>
              </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                viverra euismod odio, gravida pellentesque urna varius vitae,
                gravida pellentesque urna varius vitae.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="comment">
            <div className="img-thumbnail border-0 p-0 d-none d-md-block">
              <img
                className="avatar rounded-circle"
                alt=""
                src="img/avatars/avatar.jpg"
              />
            </div>
            <div className="comment-block">
              <div className="comment-arrow"></div>
              <span className="comment-by">
                <strong>John Doe</strong>
                <span className="float-end">
                  <div className="pb-0 clearfix">
                    <div title="Rated 3 out of 5" className="float-start">
                      <input
                        type="text"
                        className="d-none"
                        value="3"
                        title=""
                        data-plugin-star-rating=""
                        data-plugin-options="{'displayOnly': true, 'color': 'primary', 'size':'xs'}"
                      />
                    </div>
                    <div className="review-num">
                      <span className="count" itemprop="ratingCount">
                        2
                      </span>{" "}
                      reviews
                    </div>
                  </div>
                </span>
              </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                viverra odio, gravida urna varius vitae, gravida pellentesque
                urna varius vitae.
              </p>
            </div>
          </div>
        </li>
      </ul>
      <hr className="solid my-5" />
      <h4>Add a review</h4>
      <div className="row">
        <div className="col">
          <form action="#" id="submitReview" method="post">
            <div className="row">
              <div className="form-group col pb-2">
                <label className="form-label required font-weight-bold text-dark">
                  Rating
                </label>
                <input
                  type="text"
                  className="rating-loading"
                  value="0"
                  title=""
                  data-plugin-star-rating=""
                  data-plugin-options="{'color': 'primary', 'size':'sm'}"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6">
                <label className="form-label required font-weight-bold text-dark">
                  Name
                </label>
                <input
                  type="text"
                  value=""
                  data-msg-required="Please enter your name."
                  maxLength="100"
                  className="form-control"
                  name="name"
                  required
                />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-label required font-weight-bold text-dark">
                  Email Address
                </label>
                <input
                  type="email"
                  value=""
                  data-msg-required="Please enter your email address."
                  data-msg-email="Please enter a valid email address."
                  maxLength="100"
                  className="form-control"
                  name="email"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label required font-weight-bold text-dark">
                  Review
                </label>
                <textarea
                  maxLength="5000"
                  data-msg-required="Please enter your review."
                  rows="8"
                  className="form-control"
                  name="review"
                  id="review"
                  required
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="form-group col mb-0">
                <input
                  type="submit"
                  value="Post Review"
                  className="btn btn-primary btn-modern"
                  data-loading-text="Loading..."
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div role="main" className="main shop py-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-4">
            <LeftSideBar />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 text-center mb-4">
                <MiddleImgBar images={images} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                <RightSideBar {...productData} />
              </div>
            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col">
                  <div
                    id="description"
                    className="tabs tabs-simple tabs-simple-full-width-line tabs-product tabs-dark mb-2"
                  >
                    <ul
                      className="nav nav-tabs justify-content-start"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productDescription"
                          data-bs-toggle="tab"
                          aria-selected="true"
                          role="tab"
                        >
                          Description
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productInfo"
                          data-bs-toggle="tab"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Additional Information
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link nav-link-reviews font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productReviews"
                          data-bs-toggle="tab"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Reviews (2)
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content p-0">
                      <DescriptionTab />
                      <AdditionalInfoTab />
                      <ReviewsTab />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <RelatedProducts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindpathLiteAddToCart;
