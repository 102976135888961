import React, { useEffect } from "react";

import FleetManagementSolution from "../../Assests/solutions/fleet-management-solution.jpg";
import VideoTelematicsSolution from "../../Assests/solutions/video-telematics-solution.png";
import FuelMonitoringSolution from "../../Assests/solutions/fuel-monitoring-solution.png";
import DriverBehaviourSolution from "../../Assests/solutions/driver-behaviour-solution.jpg";

import ELockSolution from "../../Assests/solutions/ELockSolution.jpg";
import p6 from "../../Assests/industry/public-transport.jpg";
import ElectricVehiclesManagement from "../../Assests/solutions/ElectricVehiclesManagement.jpg";

const AllSolutionPageHeader = () => (
  <section
    className="page-header page-header-modern page-header-lg"
    style={{ backgroundColor: "#e3eff9" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 className="text-color-dark font-weight-bold">All Solutions</h1>
        </div>
        <div className="col-md-4 order-1 order-md-2 align-self-center">
          <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
            <li>
              <a
                href="/"
                className="text-color-default text-color-hover-primary text-decoration-none"
              >
                HOME
              </a>
            </li>
            <li className="active">All Solutions</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const Solution = ({ imageSrc, title, description }) => (
  <div className="col-sm-12 col-lg-6 mb-4">
    <div className="industry h-100 d-flex flex-column justify-content-between p-3 border rounded">
      <a href="#">
        <img alt="" className="img-fluid mb-3" src={imageSrc} />
      </a>
      <div>
        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none mb-2">
          <a href="#" className="text-color-dark text-color-hover-primary">
            {title}
          </a>
        </h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const SolutionList = () => {
  const solutions = [
    {
      imageSrc: FleetManagementSolution,
      title: "Fleet Management Solution",
      description: "Description for Agriculture industry.",
    },
    {
      imageSrc: VideoTelematicsSolution,
      title: "Video Telematics Solution",
      description: "Description for FMCG industry.",
    },
    {
      imageSrc:FuelMonitoringSolution,
      title: "Fuel Monitoring Solution",
      description: "Description for Logistic & Transport industry.",
    },
    {
      imageSrc: DriverBehaviourSolution,
      title: "Driver Behaviour Solution",
      description: "Description for Medical & Healthcare industry.",
    },
    {
      imageSrc: ELockSolution,
      title: "E-Lock Solution",
      description: "Description for Construction & Mining industry.",
    },
    {
      imageSrc: p6,
      title: "Route Planning Solution",
      description: "Description for Public Transport industry.",
    },
    {
      imageSrc: ElectricVehiclesManagement,
      title: "Electric Vehicles Management",
      description: "Description for Rental Vehicles industry.",
    },
  ];

  return (
    <div className="container">
      <hr className="solid my-5" />
      <div className="related-industries text-center mb-5">
        <h2 className="font-weight-bold">All Solutions</h2>
        <p className="text-muted">Explore the various industries we serve</p>
      </div>
      <div className="row">
        {solutions.map((solutions, index) => (
          <Solution
            key={index}
            imageSrc={solutions.imageSrc}
            title={solutions.title}
            description={solutions.description}
          />
        ))}
      </div>
    </div>
  );
};

const AllSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AllSolutionPageHeader />
      <SolutionList />
    </div>
  );
};

export default AllSolutions;
