import React from "react";
import "./RelatedProduct.css";
import p1 from "../../Assests/Product/product1.jpg";
import p2 from "../../Assests/Product/product2.jpg";
import p3 from "../../Assests/Product/product3.jpg";
import p4 from "../../Assests/Product/product4.jpg";
import p5 from "../../Assests/Product/product1.jpg";
import p6 from "../../Assests/Product/product2.jpg";

const Product = ({ imageSrc, productName, category, price }) => (
  <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
    <div className="product mb-0">
      <div className="product-thumb-info border-0 mb-3">
        {/* Product Badges */}
        <div className="product-thumb-info-badges-wrapper">
          <span className="badge badge-ecommerce text-bg-danger">27% OFF</span>
        </div>
        {/* Add to Cart Button */}
        <div className="addtocart-btn-wrapper">
          <a
            href="shop-cart.html"
            className="text-decoration-none addtocart-btn"
            title="Add to Cart"
          >
            <i className="icons icon-bag"></i>
          </a>
        </div>
        {/* Quick View Link */}
        <a
          href="ajax/demo-auto-services-product-quick-view.html"
          className="quick-view text-uppercase font-weight-semibold text-2"
        >
          QUICK VIEW
        </a>
        {/* Product Image */}
        <a href="shop-product-sidebar-left.html">
          <div className="product-thumb-info-image bg-light">
            <img alt="" className="img-fluid" src={imageSrc} />
          </div>
        </a>
      </div>
      {/* Product Details */}
      <div className="d-flex justify-content-between">
        <div>
          <a
            href="#"
            className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
          >
            {category}
          </a>
          <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
            <a href="#" className="text-color-dark text-color-hover-primary">
              {productName}
            </a>
          </h3>
        </div>
      </div>
      {/* Product Rating */}
      <div title="Rated 5 out of 5">
        <input
          type="text"
          className="d-none"
          value="5"
          title=""
          data-plugin-star-rating=""
          data-plugin-options="{'displayOnly': true, 'color': 'default', 'size':'xs'}"
        />
      </div>
      {/* Product Price */}
      <p className="price text-5 mb-3">
        <span className="sale text-color-dark font-weight-medium">
          {price.sale}
        </span>
        <span className="amount">{price.amount}</span>
      </p>
    </div>
  </div>
);
const RelatedProduct = () => {
  return (
    <div className="">
      <hr className="solid my-5" />
      <div className="related-products">
        <h4 className="mb-3">
          Related <strong>Products</strong>
        </h4>
        <div className="owl-carousel">
          {/* <!-- Add your product items here --> */}
        </div>
        <div className="owl-nav">
          <button type="button" role="presentation" className="owl-prev">
            <i className="fas fa-chevron-left"></i>
          </button>
          <button type="button" role="presentation" className="owl-next">
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div className="products row">
        <div className="col w-100px">
          <div
            className="products row row-gutter-sm mb-4 "
            style={{ animationDelay: "750ms" }}
          >
            {/* Product 1 */}
            <Product
              imageSrc={p1} // Replace with actual image source
              productName="Product 1 Short Name"
              category="CATEGORY"
              price={{ sale: "49,00", amount: "59,00" }}
            />

            {/* Product 2 */}
            <Product
              imageSrc={p2} // Replace with actual image source
              productName="Product 2 Short Name"
              category="CATEGORY"
              price={{ sale: "49,00", amount: "59,00" }}
            />

            {/* Product 3 */}
            <Product
              imageSrc={p3} // Replace with actual image source
              productName="Product 3 Short Name"
              category="CATEGORY"
              price={{ sale: "49,00", amount: "59,00" }}
            />

            {/* Product 4 */}
            <Product
              imageSrc={p4} // Replace with actual image source
              productName="Product 4 Short Name"
              category="CATEGORY"
              price={{ sale: "49,00", amount: "59,00" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProduct;
