import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../SignUp/SignUp.css'

const SignUp = () => {
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);

  const handleShipAddressChange = () => {
    setShipToDifferentAddress(!shipToDifferentAddress);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100 bg-primary-light ">
      <div className="col-lg-7 mb-4 mb-lg-0 ">
        <div className="card border-width-3 border-radius-0 border-color-hover-primary">
          <div className="card-body  ">
            <div className="text-align-center mb-5 text-center">
              <h2 className="text-color-dark font-weight-bold text-5-5">
                Signup Form
              </h2>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">
                  First Name <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="firstName"
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label className="form-label">
                  Last Name <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="lastName"
                  required
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="form-group col">
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control h-auto py-2 border-color-hover-primary"
                  name="companyName"
                />
              </div>
            </div> */}
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  Country/Region <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="companyName"
                />
                {/* <div className="custom-select-1">
                  <select
                    className="form-select form-control h-auto py-2 border-color-hover-primary"
                    name="country"
                    required
                  >
                    <option value="" selected></option>
                    <option value="usa">United States</option>
                    <option value="spa">Spain</option>
                    <option value="fra">France</option>
                    <option value="uk">United Kingdom</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  Street Address <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="address1"
                  placeholder="House number and street name"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="address2"
                  placeholder="Apartment, suite, unit, etc..."
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  Town/City <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto py-1 border-color-hover-primary"
                  name="city"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  State <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="city"
                  required
                />
                {/* <div className="custom-select-1">
                  <select
                    className="form-select form-control h-auto py-2"
                    name="state"
                    required
                  >
                    <option value="" selected></option>
                    <option value="ny">Nova York</option>
                    <option value="ca">California</option>
                    <option value="tx">Texas</option>
                    <option value="fl">Florida</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  ZIP <span className="text-color-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="zip"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  Phone <span className="text-color-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control h-auto  py-1 border-color-hover-primary"
                  name="phone"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">
                  Email Address <span className="text-color-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control h-auto  py-1"
                  name="email"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <div className="custom-checkbox-1">
                  <input
                    id="createAccount"
                    type="checkbox"
                    name="createAccount"
                    value="1"
                  />
                  <label htmlFor="createAccount">Create an account?</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label">Order Notes</label>
                <textarea
                  className="form-control h-auto py-1 border-color-hover-primary"
                  name="orderNotes"
                  rows="5"
                  placeholder="Notes about your order, e.g. special notes for delivery"
                  style={{ height: "88px" }}
                ></textarea>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="form-group col-12 ">
                  <button
                    type="submit"
                    class="btn btn-dark bg-primary-hover w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 btn-custom"
                    data-loading-text="Loading..."
                  >
                    Sign up
                  </button>
                   {/* <div class="divider">
                    <span class="bg-light px-4 position-absolute left-50pct top-50pct transform3dxy-n50">
                      or
                    </span>
                  </div>
                  <a
                    href="#"
                    class="btn btn-dark btn-modern w-100 text-transform-none rounded-0 font-weight-bold align-items-center d-inline-flex justify-content-center text-3 py-3"
                    data-loading-text="Loading..."
                  >
                    <i class="fab fa-facebook text-5 me-2"></i> Login With
                    Facebook
                  </a> */}
                </div>
              </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default SignUp;
