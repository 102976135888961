import React, { useState } from 'react';
import './DailyOffers.css'; // Import the CSS file for styles

const App = () => {
  const [isVisible, setIsVisible] = useState(true); // State to control visibility

  const handleClose = () => {
    setIsVisible(false); // Hide the NoticeTopBar when the button is clicked
  };

  return (
    <div>
      {isVisible && (
        <div className="notice-top-bar bg-primary" style={{ zIndex: "1200", position: "relative" }} data-sticky-start-at="100">
          <button
            className="hamburguer-btn hamburguer-btn-light notice-top-bar-close m-0 active"
            onClick={handleClose} // Close button handler
          >
            <span className="close">
              <span></span>
              <span></span>
            </span>
          </button>
          <div className="container">
            <div className="row justify-content-center py-2">
              <div className="col-9 col-md-12 text-center">
                <div className="marquee">
                  <p className="text-color-light mb-0">
                    <strong>DEAL OF THE DAY - </strong>
                    <strong>
                      <a
                        href="#"
                        className="text-color-light text-decoration-none custom-text-underline-1"
                      >
                        Check Out
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Other content of the page */}
    </div>
  );
};

export default App;
