import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const FuelMonitoringSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Electric Vehicles Management Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Electric Vehicles Management Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Battery Monitoring</h4>
              <p className="mb-0">
                Findpath’s Electric Vehicle Management includes comprehensive
                battery monitoring. This feature provides real-time updates on
                battery health, charge levels, and usage patterns. By keeping
                track of these metrics, you can ensure optimal performance and
                longevity of your electric vehicles, reducing the risk of
                unexpected downtime or battery-related issues.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Charging Status</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                Track and manage charging status with Findpath’s solution. It
                offers real-time information on charging progress, including
                current charge levels and estimated completion times. This
                feature helps fleet managers optimise charging schedules,
                ensuring vehicles are ready for use when needed and minimising
                downtime due to incomplete charging.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Energy Efficiency</h4>
                  <p className="mb-0">
                    The solution provides insights into energy efficiency for
                    electric vehicles. By analysing energy consumption patterns,
                    Findpath helps identify ways to improve efficiency, reduce
                    energy waste, and lower operational costs. This feature
                    supports better decision-making regarding energy usage and
                    contributes to more sustainable fleet operations.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Route Planning</h4>
                    <p className="mb-0">
                      Findpath’s Electric Vehicle Management includes advanced
                      route planning. It takes into account vehicle range,
                      charging station locations, and traffic conditions to
                      optimise routes. This ensures efficient travel, minimises
                      charging interruptions, and enhances overall operational
                      efficiency for electric vehicle fleets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Usage Analytics</h4>
                    <p className="mb-0">
                      Gain valuable insights into electric vehicle usage with
                      Findpath’s analytics. This feature tracks various metrics,
                      including distance travelled, charging frequency, and
                      energy consumption. By analysing this data, fleet managers
                      can make informed decisions, optimise vehicle use, and
                      improve overall fleet management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Maintenance Scheduling</h4>
                  <p className="mb-5">
                    The solution includes automated maintenance scheduling for
                    electric vehicles. It tracks vehicle performance and usage
                    data to predict maintenance needs and schedule service
                    appointments. This proactive approach helps prevent
                    breakdowns, extend vehicle life, and ensure that all
                    vehicles remain in optimal condition.
                  </p>
                  <h4 className="mb-3">Performance Tracking</h4>
                  <p className="mb-0">
                    Track the performance of your electric vehicles with
                    Findpath’s management solution. It monitors key performance
                    indicators such as acceleration, braking efficiency, and
                    overall driving dynamics. This feature helps identify areas
                    for improvement and ensures that vehicles are performing at
                    their best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FuelMonitoringSolution;
