import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WOW } from 'wowjs';
import 'jquery';


import AOS from 'aos';
import 'aos/dist/aos.css';

// Vendor css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../src/vendor/bootstrap/css/bootstrap.min.css';
import '../src/vendor/fontawesome-free/css/all.min.css';
import '../src/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../src/vendor/animate/animate.compat.css';
import '../src/vendor/simple-line-icons/css/simple-line-icons.min.css';
import '../src/vendor/owl.carousel/assets/owl.theme.default.min.css';
import '../src/vendor/magnific-popup/magnific-popup.min.css';
import '../src/vendor/bootstrap-star-rating/css/star-rating.min.css';
import '../src/vendor/bootstrap-star-rating/themes/krajee-fas/theme.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


// Theme css
import '../src/css/theme.css';
import '../src/css/theme-elements.css';
import '../src/css/theme-blog.css';
import '../src/css/theme-shop.css';
import '../src/css/skins/default.css';

// Demo css
import '../src/css/demos/demo-auto-services.css';

// Skin css
import '../src/css/skins/skin-auto-services.css';

// Theme Custom css
import '../src/css/custom.css';

import 'animate.css/animate.min.css';



// Initialize WOW.js after all imports
new WOW().init();
AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals