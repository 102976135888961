import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const DriverBehaviourSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Driver Behaviour Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Driver Behaviour Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Real-Time Monitoring</h4>
              <p className="mb-0">
                "Findpath’s Driver Behavior Solution includes Real-Time
                Monitoring, providing immediate visibility into driver behaviour
                as it happens. This feature allows fleet managers to track
                driving patterns, monitor speed, and observe adherence to
                routes. By receiving live updates, you can swiftly address any
                issues and ensure safe driving practices are consistently
                followed across all vehicles."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Driving Patterns</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                "Findpath’s Driver Behavior Solution features Driving Patterns
                analysis, which examines how drivers operate their vehicles over
                time. This tool identifies trends such as frequent braking,
                acceleration, or sharp turns. Understanding these patterns helps
                in assessing driving habits and implementing improvements. Use
                these insights to promote safer driving practices and optimise
                overall fleet performance."
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Behavioural Analysis</h4>
                  <p className="mb-0">
                    "Findpath’s Driver Behavior Solution offers Behavioral
                    Analysis, which evaluates driver actions to assess safety
                    and efficiency. This feature provides in-depth reports on
                    driving habits, including speed, lane changes, and
                    compliance with traffic rules. By analyzing driver behavior,
                    you can address areas for improvement and enhance the safety
                    and performance of your fleet."
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Safety Alerts</h4>
                    <p className="mb-0">
                      "Findpath’s Driver Behavior Solution includes Safety
                      Alerts that notify you of dangerous driving behaviors or
                      safety violations in real-time. Alerts are triggered by
                      events such as sudden braking, speeding, or harsh
                      cornering. These immediate notifications allow you to take
                      prompt action, ensuring that drivers adhere to safety
                      protocols and reducing the risk of accidents."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Performance Metrics</h4>
                    <p className="mb-0">
                      "Findpath’s Driver Behavior Solution tracks Performance
                      Metrics, monitoring various aspects of driver performance
                      such as fuel efficiency, speed consistency, and adherence
                      to schedules. This data-driven approach helps in
                      evaluating how well drivers manage their vehicles. Use
                      these metrics to identify strengths and areas for
                      improvement, ultimately enhancing overall fleet
                      efficiency."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Risk Assessment</h4>
                  <p className="mb-5">
                    "Findpath’s Driver Behavior Solution features Risk
                    Assessment, evaluating the potential hazards associated with
                    driver behavior. By analyzing driving patterns and safety
                    incidents, this feature helps identify high-risk drivers and
                    areas needing attention. Implement strategies to mitigate
                    these risks, ensuring safer driving practices and reducing
                    the likelihood of accidents."
                  </p>
                  <h4 className="mb-3">Driver Feedback</h4>
                  <p className="mb-0">
                    "Findpath’s Driver Behavior Solution provides Driver
                    Feedback, offering actionable insights into individual
                    driving performance. After analyzing driving data, it offers
                    constructive feedback to drivers on their behavior. This
                    promotes continuous improvement by highlighting areas for
                    enhancement and encouraging adherence to best practices,
                    leading to safer and more efficient driving."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DriverBehaviourSolution;
