import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const FuelMonitoringSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Fuel Monitoring Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Fuel Monitoring Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Fuel Efficiency</h4>
              <p className="mb-0">
                "Findpath's Fuel Efficiency feature helps optimize fuel usage
                across all vehicles. By analyzing consumption patterns, Findpath
                identifies areas where fuel use can be reduced. This results in
                improved overall efficiency, lower operational costs, and a more
                sustainable approach to fleet management. Achieve better fuel
                economy and operational savings with Findpath."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Real-Time Data</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                "With Findpath's Real-Time Data, you receive up-to-the-minute
                information on fuel levels and consumption for every vehicle.
                This feature enables immediate visibility into fuel usage,
                allowing for quick adjustments and better management. Stay
                informed about your fleet’s fuel status at all times, making
                data-driven decisions that enhance efficiency and
                cost-effectiveness."
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Consumption Tracking</h4>
                  <p className="mb-0">
                    "Findpath's Consumption Tracking monitors fuel usage across
                    your entire fleet. By keeping detailed records of how much
                    fuel each vehicle uses, you can identify trends and
                    potential issues. This feature provides valuable insights
                    into fuel consumption patterns, helping you make informed
                    decisions to optimize fuel usage and reduce waste."
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Cost Reduction</h4>
                    <p className="mb-0">
                      "Findpath’s Cost Reduction feature focuses on minimizing
                      fuel expenses. By identifying and addressing
                      inefficiencies in fuel usage, Findpath helps lower
                      operational costs. This feature enables you to track fuel
                      spending more effectively, apply cost-saving strategies,
                      and achieve substantial savings while maintaining optimal
                      fleet performance."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Automated Alerts</h4>
                    <p className="mb-0">
                      "Findpath’s Automated Alerts notify you of critical
                      fuel-related events in real-time. Whether it’s low fuel
                      levels or unexpected consumption spikes, these alerts help
                      you respond swiftly to issues. Stay ahead of potential
                      problems and ensure efficient fuel management with timely
                      notifications that keep your fleet running smoothly."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Accurate Monitoring</h4>
                  <p className="mb-5">
                    "Findpath's Accurate Monitoring provides precise tracking of
                    fuel levels and consumption. This feature ensures you
                    receive reliable data for each vehicle, reducing the chances
                    of errors and discrepancies. With accurate monitoring, you
                    can maintain better control over fuel usage, optimize
                    efficiency, and make informed decisions based on trustworthy
                    information."
                  </p>
                  <h4 className="mb-3">Fleet Management</h4>
                  <p className="mb-0">
                    "Findpath’s Fleet Management feature integrates fuel
                    monitoring into your overall fleet operations. By combining
                    fuel data with vehicle performance metrics, this solution
                    enhances your ability to manage and optimize your fleet.
                    Improve operational efficiency, streamline management tasks,
                    and ensure effective oversight of your entire vehicle
                    fleet."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FuelMonitoringSolution;
