import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import p1 from "../../Assests/Product/product1.jpg";
import p2 from "../../Assests/Product/product2.jpg";
import p3 from "../../Assests/Product/product3.jpg";
import p4 from "../../Assests/Product/product4.jpg";

const Product = ({ imageSrc, productName, category, price }) => (
  <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
    <div className="product mb-0">
      <div className="product-thumb-info border-0 mb-3">
        {/* Product Badges */}
        <div className="product-thumb-info-badges-wrapper">
          <span className="badge badge-ecommerce text-bg-danger">50% OFF</span>
        </div>
        {/* Add to Cart Button */}
        <div className="addtocart-btn-wrapper">
          <a
            href="shop-cart.html"
            className="text-decoration-none addtocart-btn"
            title="Add to Cart"
          >
            <i className="icons icon-bag"></i>
          </a>
        </div>
        {/* Quick View Link */}
        <a
          href="ajax/demo-auto-services-product-quick-view.html"
          className="quick-view text-uppercase font-weight-semibold text-2"
        >
          QUICK VIEW
        </a>
        {/* Product Image */}
        <a href="shop-product-sidebar-left.html">
          <div className="product-thumb-info-image bg-light">
            <img alt="" className="img-fluid" src={imageSrc} />
          </div>
        </a>
      </div>
      {/* Product Details */}
      <div className="d-flex justify-content-between">
        <div>
          <a
            href="#"
            className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
          >
            {category}
          </a>
          <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
            <a href="#" className="text-color-dark text-color-hover-primary">
              {productName}
            </a>
          </h3>
        </div>
      </div>
      {/* Product Rating */}
      <div title="Rated 5 out of 5">
        <input
          type="text"
          className="d-none"
          value="5"
          title=""
          data-plugin-star-rating=""
          data-plugin-options="{'displayOnly': true, 'color': 'default', 'size':'xs'}"
        />
      </div>
      {/* Product Price */}
      <p className="price text-5 mb-3">
        <span className="sale text-color-dark font-weight-medium">
          {price.sale}
        </span>
        <span className="amount">{price.amount}</span>
      </p>
    </div>
  </div>
);

const Products = () => {
  const navigate = useNavigate(); 

  const handleViewAllProducts = () => {
    navigate("/all-products"); // Navigate to the specified route
  };

  return (
    <section
      className="shop section section-height-4 border-0 m-0"
      style={{ backgroundColor: "#e3eff9" }}
    >
      <div className="container">
        <div className="row justify-content-center pb-3 mb-4">
          {/* Title and Description */}
          <div className="col-lg-8 text-center">
            <div className="overflow-hidden">
              <h2
                className="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation animated maskUp appear-animation-visible"
                data-appear-animation="maskUp"
                data-appear-animation-delay="250"
                style={{ animationDelay: "250ms" }}
              >
                HOT Products
              </h2>
            </div>
            <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
              <hr
                className="my-0 appear-animation animated customLineProgressAnim appear-animation-visible"
                data-appear-animation="customLineProgressAnim"
                data-appear-animation-delay="600"
                style={{ animationDelay: "600ms" }}
              />
            </div>
            <p
              className="font-weight-light text-3-5 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="500"
              style={{ animationDelay: "500ms" }}
            >
              {/* Cras a elit sit amet leo accumsan volutpat. Suspendisse hendrerit
              vehicula leo, vel efficitur felis ultrices non. Integer aliquet
              ullamcorper dolor, quis sollicitudin. */}
            </p>
          </div>
        </div>
        {/* Product Cards */}
        <div className="d-flex justify-content-center">
          <div
            className="products row row-gutter-sm mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="750"
            style={{ animationDelay: "750ms" }}
          >
             {/* Product 1 */}
             <Product
              imageSrc={p2}
              productName="Findpath Lite"
              category="Findpath Lite"
              price={{ sale: "₹2999", amount: "₹5,998" }}
            />
            {/* Product 2 */}
            <Product
              imageSrc={p1}
              productName="Findpath Pro"
              category="Findpath Pro"
              price={{ sale: "₹3,999", amount: "₹7,998" }}
            />
            {/* Product 3 */}
            <Product
              imageSrc={p3}
              productName="Findpath Go"
              category="Findpath Go"
              price={{ sale: "₹3,499", amount: "₹6,998" }}
            />
            {/* Product 4 */}
            <Product
              imageSrc={p4}
              productName="Findpath Go Plus"
              category="Findpath Go Plus"
              price={{ sale: "₹6,999", amount: "₹13,998" }}
            />
          </div>
        </div>

        {/* View All Products Button */}
        <div className="row">
          <div className="col text-center">
            <button
              onClick={handleViewAllProducts} // Use onClick with a function call
              className="btn btn-primary custom-btn-border-radius font-weight-bold text-3 btn-px-5 btn-py-3 appear-animation animated fadeInUpShorterPlus appear-animation-visible"
              data-appear-animation="fadeInUpShorterPlus"
              data-appear-animation-delay="800"
              style={{ animationDelay: "800ms" }}
            >
              VIEW ALL PRODUCTS
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
