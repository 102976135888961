import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const PrivacyPolicyHeader = () => {
    const navigate = useNavigate();

    return (
      <section
        className="page-header page-header-modern page-header-lg"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">Privacy Policy</h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active">Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="tremspolicy_wrapper">
      <PrivacyPolicyHeader />
      <div className="container">
        <div className="tremspolicy_content">
          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Introduction</h3>
            <p className="f16 mb20">
              In today's digital age, privacy policies are more crucial than
              ever. Whether you're running a small blog or a large e-commerce
              site, a privacy policy is essential. But why is it so important,
              and what should it include? Let's dive deep into the world of
              privacy policies to understand their significance and how to craft
              one that keeps both your business and your users safe.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className=" mb-3 mt-3">What is a Privacy Policy?</h3>
            <p className="f16">
              A privacy policy is a statement that explains how an organization
              collects, uses, discloses, and manages a customer's data. It's not
              just a document to tick off your compliance checklist; it's a
              promise to your users about how you will handle their information.
              Legally, privacy policies are required in many jurisdictions to
              protect consumers' personal information.
              <br />
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Why Do You Need a Privacy Policy?</h3>
            <h5>Legal Compliance</h5>
            <p className="f16">
              First and foremost, a privacy policy helps ensure your business
              complies with various data protection laws like the GDPR in Europe
              and CCPA in California. These regulations mandate that businesses
              transparently disclose their data practices.
            </p>

            <h5>Building Trust with Users</h5>
            <p className="f16">
              A well-crafted privacy policy helps build trust with your users.
              When users know their data is handled responsibly, they're more
              likely to engage with your site and share their information.
            </p>
            <h5>Managing User Expectations</h5>
            <p className="f16">
              Clear communication about your data practices helps manage user
              expectations. By outlining how you collect, use, and protect data,
              you reduce the risk of misunderstandings and legal disputes.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Key Components of a Privacy Policy</h3>
            <h5>Information Collection</h5>
            <p className="f16 mb20">
              Your privacy policy should start by detailing what information you
              collect. This can include personal details like names and email
              addresses, as well as non-personal information like browser types
              and device identifiers.
            </p>
            <h5>Information Usage</h5>
            <p className="f16 mb20">
              Explain how the collected information is used. Are you using it to
              improve user experience, send newsletters, or for targeted
              advertising? Be specific to avoid any ambiguity.
            </p>
            <h5>Data Protection</h5>
            <p className="f16 mb20">
              Detail the measures you take to protect user information. This
              might involve data encryption, secure servers, and regular
              security audits.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Types of Information Collected</h3>
            <h5>Personal Information</h5>
            <p className="f16 mb20">
              Personal information refers to any data that can identify an
              individual. This includes names, email addresses, phone numbers,
              and payment information.
            </p>
            <h5>Non-Personal Information</h5>
            <p className="f16 mb20">
              Non-personal information is data that cannot be used to identify a
              specific individual. This might include aggregated statistics,
              user behavior patterns, and technical information like IP
              addresses.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">How Information is Collected</h3>
            <h5>Direct Collection</h5>
            <p className="f16 mb20">
              Information collected directly from users typically includes
              registration forms, contact forms, and purchase information.
            </p>
            <h5>Indirect Collection</h5>
            <p className="f16 mb20">
              Indirect collection happens through tracking technologies like
              cookies, which monitor user activity and preferences over time.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Usage of Collected Information</h3>
            <h5>Enhancing User Experience</h5>
            <p className="f16 mb20">
              Collected data helps personalize the user experience. For example,
              knowing a user's preferences allows you to recommend relevant
              content or products.
            </p>
            <h5>Marketing and Communication</h5>
            <p className="f16 mb20">
              User information is often used for marketing purposes, such as
              sending promotional emails or tailored advertisements.
            </p>
            <h5>Legal Purposes</h5>
            <p className="f16 mb20">
              Sometimes, data is needed to comply with legal obligations, such
              as verifying the age of users or preventing fraud.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Sharing of Information</h3>
            <h5>Third-Party Services</h5>
            <p className="f16 mb20">
              It's common to share data with third-party services like payment
              processors, analytics providers, and advertising partners. Ensure
              your privacy policy lists these entities and explains why sharing
              occurs.
            </p>
            <h5>Legal Obligations</h5>
            <p className="f16 mb20">
              In certain situations, you may be required to share user
              information to comply with legal processes or government requests.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Protecting User Information</h3>
            <h5>Data Encryption</h5>
            <p className="f16 mb20">
              Data encryption ensures that information transmitted between users
              and your website is secure and cannot be intercepted.
            </p>
            <h5>Access Controls</h5>
            <p className="f16 mb20">
              Implementing access controls limits who can view or manipulate
              user data within your organization, reducing the risk of internal
              data breaches.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">User Rights and Control</h3>
            <h5>Access to Information</h5>
            <p className="f16 mb20">
              Users should have the right to access the information you hold
              about them. This transparency helps build trust and allows users
              to verify the accuracy of their data.
            </p>
            <h5>Data Rectification</h5>
            <p className="f16 mb20">
              Your users should be able to correct any inaccuracies in their
              data. This ensures that you always have up-to-date and accurate
              information.
            </p>
            <h5>Data Deletion</h5>
            <p className="f16 mb20">
              Also known as the "right to be forgotten," users should have the
              option to delete their data from your systems if they choose to
              discontinue their relationship with your business.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Children's Privacy</h3>
            <h5>COPPA Compliance</h5>
            <p className="f16 mb20">
              If your website targets children under the age of 13, you must
              comply with the Children's Online Privacy Protection Act (COPPA).
              This involves obtaining verifiable parental consent before
              collecting personal information from children.
            </p>
            <h5>Special Considerations</h5>
            <p className="f16 mb20">
              Children's data requires special protection. Ensure your privacy
              policy addresses these needs clearly and explicitly.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Changes to Privacy Policy</h3>
            <h5>Notification of Changesn</h5>
            <p className="f16 mb20">
              Whenever you update your privacy policy, notify your users. This
              can be done through email or a prominent notice on your website.
            </p>
            <h5>How Changes are Communicated</h5>
            <p className="f16 mb20">
              Explain how changes to the privacy policy will be communicated and
              provide a clear version history.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              How to Create an Effective Privacy Policy
            </h3>
            <h5>Clarity and Transparency</h5>
            <p className="f16 mb20">
              Your privacy policy should be easy to understand. Avoid legal
              jargon and clearly explain your data practices.
            </p>
            <h5>Legal Consultation</h5>
            <p className="f16 mb20">
              Consulting with a legal professional can help ensure your privacy
              policy meets all regulatory requirements and protects your
              business from potential liabilities.
            </p>
            <h5>Regular Updates</h5>
            <p className="f16 mb20">
              Regularly review and update your privacy policy to keep up with
              changes in laws and your business practices.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Common Mistakes to Avoid</h3>
            <h5>Vague Language</h5>
            <p className="f16 mb20">
              Avoid vague terms that can confuse users about your data
              practices. Be specific about what data you collect, how it's used,
              and with whom it's shared.
            </p>
            <h5>Inconsistent Information</h5>
            <p className="f16 mb20">
              Ensure all sections of your privacy policy are consistent.
              Inconsistent information can lead to confusion and erode trust.
            </p>
            <h5>Conclusion</h5>
            <p className="f16 mb20">
              In conclusion, a well-crafted privacy policy is crucial for any
              business operating online. It ensures legal compliance, builds
              trust with users, and clearly communicates your data practices.
              Regular updates and clear, transparent language are key to
              maintaining an effective privacy policy. Remember, your privacy
              policy is more than just a legal requirement—it's a commitment to
              your users about how their information is handled.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Contact Us</h3>
            <p className="f16 mb20">
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us at:{" "}
              <a role="button" className="text-color-prime">
                support@findpath.co.in
              </a>
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
