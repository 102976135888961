import React from 'react';
import W2 from '../../Assests/w2.png';
import '../FloatingWhatsapp/FloatingWhatsapp.css'

const FloatingWhatsApp = () => {
  return (
    <div className="floating-whatsapp">
      <a href="http://wa.me/+919958799582" target="_blank" rel="noopener noreferrer">
        <img src={W2} alt="WhatsApp" />
      </a>
    </div>
  );
}

export default FloatingWhatsApp;
