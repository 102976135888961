import React, { useState, useEffect } from "react";
import LeftSideBar from "../../Components/LeftSideBar/LeftSideBar";
import RightSideBar from "../../Components/RightSideBar/RightSideBar";
import MiddleImgBar from "../../Components/MiddleImgBar/MiddleImgBar";
import RelatedProducts from "../../Components/RelatedProduct/RelatedProduct";

import image1 from "../../Assests/Product/FindpathLite/product1.jpg";
import image2 from "../../Assests/Product/FindpathLite/product2.jpg";
import image3 from "../../Assests/Product/FindpathLite/product3.jpg";
import image4 from "../../Assests/Product/FindpathLite/product4.jpg";
import image5 from "../../Assests/Product/FindpathLite/product5.jpg";

import avtar from "../../img/avatars/avatar.jpg";
import avtar4 from "../../img/avatars/avatar-4.jpg";

const images = [image1, image2, image3, image4, image5];

const FindpathLiteAddToCart = () => {
  const productData = {
    productName: "Findpath Lite",
    reviews: 25,
    salePrice: 2999.0,
    originalPrice: 5998.0,
    description:
      "Findpath Lite GPS Tracker is a Cost-Effective choice for keeping track of your vehicle or asset, offering reliable monitoring and peace of mind.",
    availability: "AVAILABLE",
    sku: "1234567890",
    customName1: "TRACTOR",
    customEmoji1: "🚜",
    customName2: "Bus tracker",
    customEmoji2: "🚌",
    customName3: "TRUCK",
    customEmoji3: "🚚",
    customName4: "Bike Tracker",
    customEmoji4: "🏍️",
    antenna: "Built-in GPS ceramic, built-in communication FPC",
    ledIndicator: "GPS: Yellow；GSM: Red",
    standbyBattery: "(3.7V 55mAh Soft Pack Lithium Battery)",
    workingVoltage: "DC 9V - 90V",
    workingCurrent: "25mA@12V (Static Current 10mA)",
    dealPrice: 2999,
    extraDiscount: 2999, // Pass the extra discount amount
    mrp: 5998, // Pass the M.R.P.
    discount: "50% off",
    saleDuration: 25200, // For a 7-hour countdown
    hurryMessage: "Hurry, limited time offer!",
  };

  // Description component
  const DescriptionTab = () => (
    <div
      className="tab-pane px-0 py-3 active"
      id="productDescription"
      role="tabpanel"
    >
      <p>
        Findpath Lite GPS Tracker offers a cost-effective solution for reliable
        vehicle monitoring. Designed for those seeking affordable yet effective
        tracking, this device ensures you stay informed about the location of
        your vehicle, whether it's a bus, tractor, truck, or bike.
      </p>
      <p className="">
        The Findpath Lite GPS Tracker features real-time location updates,
        allowing you to monitor your vehicle’s whereabouts at all times. This
        capability is essential for enhancing security and managing vehicle use
        effectively. The device is also equipped with geofencing functionality,
        enabling you to set virtual boundaries and receive alerts if the vehicle
        moves outside these designated areas, helping to prevent unauthorised
        use or theft.
      </p>
      <p>
        Its user-friendly installation process makes it easy to integrate with
        various types of vehicles, including buses, tractors, trucks, and bikes.
        Additionally, Findpath Lite is known for its efficient battery life,
        providing long-lasting performance with minimal maintenance.
      </p>
      <p>
        Choose Findpath Lite GPS Tracker for a reliable, budget-friendly option
        that delivers real-time tracking and enhances the security of your
        buses, tractors, trucks, and bikes. It’s an ideal choice for those
        looking for effective tracking solutions without the extra cost.
      </p>
    </div>
  );

  // Additional Information component
  const AdditionalInfoTab = () => (
    <div className="tab-pane px-0 py-3" id="productInfo" role="tabpanel">
      <table className="table table-striped m-0">
        <tbody>
          <tr>
            <th className="border-top-0">Warranty:</th>
            <td className="border-top-0">1 Year of hardware warranty.T&C</td>
          </tr>
          <tr>
            <th>Quick return:</th>
            <td>7 days easy return policy.</td>
          </tr>
          <tr>
            <th>On time Delivery:</th>
            <td>Quick delivery Available at major cities.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  // Reviews component
  const ReviewsTab = () => (
    <div className="tab-pane px-0 py-3" id="productReviews" role="tabpanel">
      <ul className="comments">
        <li>
          <div className="comment">
            <div className="img-thumbnail border-0 p-0 d-none d-md-block">
              <img className="avatar rounded-circle" alt="" src={avtar} />
            </div>
            <div className="comment-block">
              <div className="comment-arrow"></div>
              <span className="comment-by">
                <strong>Mohit Bhatt</strong>
                <span className="float-end">
                  <div className="pb-0 clearfix">
                    <div title="Rated 3 out of 5" className="float-start">
                      <input
                        type="text"
                        className="d-none"
                        value="3"
                        title=""
                        data-plugin-star-rating=""
                        data-plugin-options="{'displayOnly': true, 'color': 'primary', 'size':'xs'}"
                      />
                    </div>
                    <div className="review-num">
                      <span className="count" itemprop="ratingCount">
                        1
                      </span>{" "}
                      review
                    </div>
                  </div>
                </span>
              </span>
              <p>
                This GPS tracker exceeded my expectations! Real-time tracking is
                accurate, and the battery lasts forever. It’s easy to set up and
                fits perfectly in my car. Highly recommend for anyone needing
                reliable tracking!
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="comment">
            <div className="img-thumbnail border-0 p-0 d-none d-md-block">
              <img className="avatar rounded-circle" alt="" src={avtar4} />
            </div>
            <div className="comment-block">
              <div className="comment-arrow"></div>
              <span className="comment-by">
                <strong>Sunny Ruhil</strong>
                <span className="float-end">
                  <div className="pb-0 clearfix">
                    <div title="Rated 3 out of 5" className="float-start">
                      <input
                        type="text"
                        className="d-none"
                        value="3"
                        title=""
                        data-plugin-star-rating=""
                        data-plugin-options="{'displayOnly': true, 'color': 'primary', 'size':'xs'}"
                      />
                    </div>
                    <div className="review-num">
                      <span className="count" itemprop="ratingCount">
                        2
                      </span>{" "}
                      review
                    </div>
                  </div>
                </span>
              </span>
              <p>
                I love this GPS tracker! It provides instant updates on my kids'
                whereabouts. The app is user-friendly, and the device is compact
                and discreet. I feel so much safer knowing I can track them
                anytime!
              </p>
            </div>
          </div>
        </li>
      </ul>
      <hr className="solid my-5" />
      {/* <h4>Add a review</h4>
      <div className="row">
        <div className="col">
          <form action="#" id="submitReview" method="post">
            <div className="row">
              <div className="form-group col pb-2">
                <label className="form-label required font-weight-bold text-dark">
                  Rating
                </label>
                <input
                  type="text"
                  className="rating-loading"
                  value="0"
                  title=""
                  data-plugin-star-rating=""
                  data-plugin-options="{'color': 'primary', 'size':'sm'}"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6">
                <label className="form-label required font-weight-bold text-dark">
                  Name
                </label>
                <input
                  type="text"
                  value=""
                  data-msg-required="Please enter your name."
                  maxLength="100"
                  className="form-control"
                  name="name"
                  required
                />
              </div>
              <div className="form-group col-lg-6">
                <label className="form-label required font-weight-bold text-dark">
                  Email Address
                </label>
                <input
                  type="email"
                  value=""
                  data-msg-required="Please enter your email address."
                  data-msg-email="Please enter a valid email address."
                  maxLength="100"
                  className="form-control"
                  name="email"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label required font-weight-bold text-dark">
                  Review
                </label>
                <textarea
                  maxLength="5000"
                  data-msg-required="Please enter your review."
                  rows="8"
                  className="form-control"
                  name="review"
                  id="review"
                  required
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="form-group col mb-0">
                <input
                  type="submit"
                  value="Post Review"
                  className="btn btn-primary btn-modern"
                  data-loading-text="Loading..."
                />
              </div>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );

  return (
    <div role="main" className="main shop py-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-4">
            <LeftSideBar />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 text-center mb-4">
                <MiddleImgBar images={images} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                <RightSideBar {...productData} />
              </div>
            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col">
                  <div
                    id="description"
                    className="tabs tabs-simple tabs-simple-full-width-line tabs-product tabs-dark mb-2"
                  >
                    <ul
                      className="nav nav-tabs justify-content-start"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productDescription"
                          data-bs-toggle="tab"
                          aria-selected="true"
                          role="tab"
                        >
                          Description
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productInfo"
                          data-bs-toggle="tab"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Additional Information
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link nav-link-reviews font-weight-bold text-3 text-uppercase py-2 px-3"
                          href="#productReviews"
                          data-bs-toggle="tab"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Reviews (2)
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content p-0">
                      <DescriptionTab />
                      <AdditionalInfoTab />
                      <ReviewsTab />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <RelatedProducts /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindpathLiteAddToCart;
