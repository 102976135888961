import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const FuelMonitoringSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Fleet Management Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active">Fleet Management Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Efficient Tracking</h4>
              <p className="mb-0">
                Findpath’s Fleet Management Solution offers efficient tracking
                of all fleet vehicles. By utilising advanced GPS technology, it
                provides real-time location updates, ensuring that you can
                monitor your fleet’s whereabouts with precision. This efficient
                tracking enhances route planning, reduces downtime, and ensures
                better resource management across your entire fleet.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Real-Time Monitoring</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                With Findpath’s Fleet Management Solution, you gain access to
                real-time monitoring of your vehicles. This feature allows fleet
                managers to observe live data, including vehicle location,
                speed, and driving behaviour. Real-time monitoring enables swift
                decision-making and immediate response to any issues, improving
                overall fleet safety and operational efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Route Optimization</h4>
                  <p className="mb-0">
                    Findpath’s solution includes route optimization to enhance
                    fleet efficiency. By analysing traffic patterns, vehicle
                    locations, and delivery schedules, it suggests the most
                    efficient routes. This feature reduces travel time, fuel
                    consumption, and operational costs, ensuring timely
                    deliveries and better resource allocation throughout your
                    fleet operations.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Cost Reduction</h4>
                    <p className="mb-0">
                      The Fleet Management Solution by Findpath focuses on cost
                      reduction through various means. By optimising routes,
                      monitoring fuel usage, and preventing unauthorised vehicle
                      use, it lowers operational expenses. Implementing this
                      solution can lead to significant savings in fuel costs,
                      maintenance, and overall fleet management, boosting your
                      bottom line.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Performance Analysis</h4>
                    <p className="mb-0">
                      Findpath’s Fleet Management Solution provides detailed
                      performance analysis for each vehicle in your fleet. This
                      includes monitoring fuel efficiency, vehicle usage, and
                      driver behaviour. The analysis helps identify areas for
                      improvement, allowing fleet managers to implement
                      strategies that enhance performance, reduce costs, and
                      improve overall fleet efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Driver Behaviour</h4>
                  <p className="mb-5">
                    The solution includes features to monitor and analyse driver
                    behaviour. By tracking metrics such as speeding, harsh
                    braking, and idling, Findpath helps identify risky driving
                    habits. Addressing these behaviours through training and
                    feedback can lead to safer driving practices, reduced
                    accident rates, and lower insurance costs for your fleet.
                  </p>
                  <h4 className="mb-3">Maintenance Alerts</h4>
                  <p className="mb-0">
                    Findpath’s Fleet Management Solution offers proactive
                    maintenance alerts. It tracks vehicle performance and
                    schedules regular maintenance checks based on usage data. By
                    receiving timely alerts for upcoming service needs, fleet
                    managers can prevent breakdowns, extend vehicle lifespan,
                    and ensure that all vehicles are maintained in optimal
                    condition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FuelMonitoringSolution;
