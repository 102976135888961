import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const PrivacyPolicyHeader = () => {
  const navigate = useNavigate();

  return (
    <section
      className="page-header page-header-modern page-header-lg"
      style={{
        backgroundColor: "#e3eff9",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 className="text-color-dark font-weight-bold">
              Terms and Conditions
            </h1>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
              <li>
                <a
                  role="button"
                  onClick={() => navigate("/")}
                  className="text-color-default text-color-hover-primary text-decoration-none"
                >
                  HOME
                </a>
              </li>
              <li className="active">Terms and Conditions</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
const TermsAndCondition = () => {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="tremspolicy_wrapper">
      <PrivacyPolicyHeader />
      <div className="container">
        <div className="tremspolicy_content">
          <div className="termspolicy_content__wrapper clearfix">
            <p className="f16 mb20">
              In today's digital age, terms and conditions are more crucial than
              ever. They serve as the foundation of trust and transparency
              between service providers and users. Let's delve into why these
              documents matter, with a special focus on the terms and conditions
              of Findpath.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className=" mb-3 mt-3">What is a Privacy Policy?</h3>
            <p className="f16">
              A privacy policy is a statement that explains how an organization
              collects, uses, discloses, and manages a customer's data. It's not
              just a document to tick off your compliance checklist; it's a
              promise to your users about how you will handle their information.
              Legally, privacy policies are required in many jurisdictions to
              protect consumers' personal information.
              <br />
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">What Are Terms and Conditions?</h3>
            <p className="f16">
              Terms and conditions (T&Cs) are legal agreements between a service
              provider and its users. They outline the rules, responsibilities,
              and limitations of using the service. These agreements protect
              both parties by setting clear expectations and guidelines.
            </p>

            <h3>Why Every Service Needs Terms and Conditions</h3>
            <p className="f16">
              T&Cs are essential for protecting both the service provider and
              the user. They establish clear guidelines on how the service
              should be used, which helps prevent misunderstandings and misuse.
              Moreover, they ensure that users are aware of their rights and
              obligations, fostering a safer and more transparent user
              experience.
            </p>
            <h3>Key Elements of Effective Terms and Conditions</h3>
            <p className="f16">
              To be effective, T&Cs should cover several key areas:
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">User Responsibilities</h3>
            <p className="f16 mb20">
              This section outlines what users are expected to do while using
              the service. It typically includes acceptable use policies and
              prohibited activities.
            </p>
            <h3>Service Limitations</h3>
            <p className="f16 mb20">
              Here, the service provider details any limitations of their
              service, including availability and liability limitations.
            </p>
            <h3>Payment Terms</h3>
            <p className="f16 mb20">
              This part covers the financial aspects, such as subscription fees
              and refund policies.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Privacy Policy</h3>
            <p className="f16 mb20">
              A critical component, this section explains how user data is
              collected, used, and protected.
            </p>
            <h3>Dispute Resolution</h3>
            <p className="f16 mb20">
              This outlines the process for resolving any disputes that may
              arise, including governing law and arbitration clauses.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              Detailed Breakdown of Findpath Terms and Conditions
            </h3>
            <p className="f16 mb20">
              To understand how these elements come together, let's look at
              Findpath's T&Cs.
            </p>
            <h3>User Responsibilities</h3>
            <p className="f16 mb20">
              Findpath clearly outlines acceptable use policies, ensuring users
              know what is expected of them. Prohibited activities are also
              detailed to prevent misuse.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Service Limitations</h3>
            <p className="f16 mb20">
              The company provides clear information on service availability and
              limits its liability to protect against unforeseen issues.
            </p>
            <h3>Payment Terms</h3>
            <p className="f16 mb20">
              Subscription fees are transparent, and the refund policy is
              outlined to ensure users are aware of financial commitments.
            </p>
            <h3>Privacy Policy</h3>
            <p className="f16 mb20">
              Findpath's privacy policy explains data collection and usage,
              emphasizing their commitment to user privacy.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Dispute Resolution</h3>
            <p className="f16 mb20">
              The terms specify the governing law and arbitration process,
              providing a clear path for resolving disputes.
            </p>
            <h3>How to Read and Understand Terms and Conditions</h3>
            <p className="f16 mb20">
              Users often overlook T&Cs due to their complexity. However, it's
              crucial to read and understand these documents. Look for key
              sections like user responsibilities, service limitations, and
              privacy policies. Don't hesitate to ask questions if something is
              unclear.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              Importance of Updating Terms and Conditions
            </h3>
            <p className="f16 mb20">
              Regularly updating T&Cs is essential to keep up with legal changes
              and reflect updates in the service. This ensures ongoing
              protection for both the provider and users.
            </p>
            <h3>Case Study: Findpath's Approach to Terms and Conditions</h3>
            <p className="f16 mb20">
              Findpath's T&Cs are a good example of comprehensive and
              user-friendly terms. They cover all necessary areas effectively
              but can always look for areas to simplify language further to
              enhance user understanding.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Conclusion</h3>

            <p className="f16 mb20">
              Terms and conditions are vital for ensuring a transparent and safe
              interaction between service providers and users. They protect both
              parties by clearly outlining rules, responsibilities, and
              limitations. Regular updates and clear language make these
              documents more effective and user-friendly.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Contact Us</h3>
            <p className="f16 mb20">
              If you have any questions or concerns about this terms and conditions policy, please contact us at:{" "}
              <a role="button" className="text-color-prime">
                support@findpath.co.in
              </a>
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndCondition

