import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//header footer
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";

//Pages
import Homepage from "./Pages/HomePage/HomePage";
import BrandPartner from "./Components/BrandPartner/BrandPartner";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
import Blog from "./Pages/Blog/Blog";
import BlogPost from "./Pages/Blog/BlogPost";
import AboutUs from "./Pages/AboutUs/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./Pages/ReturnPolicy/ReturnPolicy";
import ScopeOfWarranty from "./Pages/ScopeOfWarranty/ScopeOfWarranty";
import FAQs from "./Pages/FAQs/FAQs";
import OurCompany from "./Pages/OurCompany/OurCompany";
import TermsAndCondition from "./Pages/TermsAndCondition/TermsAndCondition";
import AllProducts from "./Components/AllProducts/AllProducts.jsx";
import GetADemo from "./Pages/GetADemo/GetADemo";
import DeviceActivation from "./Pages/DeviceActivation/DeviceActivation.jsx";
import DeviceActivationThankYou from "./Components/ThankyouMessage/Thanku.jsx"
import DeviceRenewal from "./Pages/DeviceRenewal/DeviceRenewal.jsx";
import PartnerWishUs from "./Pages/PartnerWithUs/PartnerWithUs.jsx";
import AllSolutions from "./Pages/AllSoltions/AllSolutions.jsx";
import WhatsNew from "./Pages/WhatsNew/WhatsNew.jsx"
import ChatBot from "./Components/ChatBot/ChatBot.jsx"
import ELockSolution from "./Pages/Solutions/ELockSolutions.jsx"
import RoutePlanningSolution from "./Pages/Solutions/RoutePlanningSolution.jsx";
import FuelMonitoringSolution from "./Pages/Solutions/FuelMonitoringSolution.jsx";
import DriveBehaviourSolution from "./Pages/Solutions/DriverBehaviourSolution.jsx";
import VideoTelematicsSolution from "./Pages/Solutions/VideoTelematicsSolution.jsx";
import FleetManagementSolution from "./Pages/Solutions/FleetManagementSolution.jsx";
import ElectricVehiclesManagement from "./Pages/Solutions/ElectricVehiclesManagement.jsx"
//addtocart
import FindpathLite from "./Pages/AddToCart/FindpathLite.jsx";
import FindpathPro from "./Pages/AddToCart/FindpathPro.jsx";
import FindpathGo from "./Pages/AddToCart/FindpathGo.jsx";
import FindpathGoPlus from "./Pages/AddToCart/FindpathGoPlus.jsx";
import FindpathELock from "./Pages/AddToCart/FindpathELock.jsx";
import FindpathDashcams from "./Pages/AddToCart/FindpathDashcams.jsx";
import FindpathFuelSensor from "./Pages/AddToCart/FindpathFuelSensor.jsx";

//whatsapp icon
import FloatingWhatsApp from "./Components/FloatingWhatsapp/FloatingWhatsapp";


function App() {
  return (
    <div>
      <Router>
        <div>
          <NavBar />
          <Routes>
            {/* All Page here */}
            <Route path="/" element={<Homepage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogpost" element={<BlogPost />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/getademo" element={<GetADemo />} />
            <Route path="/gps-activation" element={<DeviceActivation />} />
            <Route path="/gps-renewal" element={<DeviceRenewal />} />
            <Route path="/partnerwithus" element={<PartnerWishUs />} />
            <Route path="/what's-new" element={<WhatsNew/>}/>
            <Route path="/thank-you" element={<DeviceActivationThankYou/>}/>
            <Route path="/solution/e-lock_solution" element={<ELockSolution/>}/> 
            <Route path="/solution/route-planning-solution" element={<RoutePlanningSolution/>}/>
            <Route path="/solution/fuel-monitoring-solution" element={<FuelMonitoringSolution/>}/>
            <Route path="/solution/drive-behaviour-solution" element={<DriveBehaviourSolution/>}/>
            <Route path="/solution/video-telematics-solution" element={<VideoTelematicsSolution/>}/>
            <Route path="/solution/fleet-management-solution" element={<FleetManagementSolution/>}/>
            <Route path="/solution/electric-vehicles-management" element={<ElectricVehiclesManagement/>}/>


            {/* product page for mobile */}
            <Route path="/all-products" element={<AllProducts />} />
            <Route path="/all-solutions" element={<AllSolutions />} />

            {/* All page add to cart */}
            <Route path="/product/findpath-lite" element={<FindpathLite />} />
            <Route path="/product/findpath-pro" element={<FindpathPro />} />
            <Route path="/product/findpath-go" element={<FindpathGo />} />
            <Route
              path="/product/findpath-go-plus"
              element={<FindpathGoPlus />}
            />
            <Route
              path="/product/findpath-e-lock"
              element={<FindpathELock />}
            />
            <Route
              path="/product/findpath-dashcams"
              element={<FindpathDashcams />}
            />
            <Route
              path="/product/findpath-fuel-sensor"
              element={<FindpathFuelSensor />}
            />
            {/* All Footer link  */}
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/returnpolicy" element={<ReturnPolicy />} />
            <Route path="/warranty-policy" element={<ScopeOfWarranty />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/termsandconditions" element={<TermsAndCondition />} />
            <Route path="/ourcompany" element={<OurCompany />} />
            <Route path="/chat-bot" element={<ChatBot/>}/>
          </Routes>

          <FloatingWhatsApp />
          <BrandPartner />
          <Footer />
        </div>
        
      </Router>
    </div>
  );
}

export default App;
