import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Slider.css';

import slide1 from '../../Assests/Banners/banner1.png';
import slide2 from '../../Assests/Banners/banner2.png';
import slide3 from '../../Assests/Banners/banner3.png';
// import slide4 from '../../Assests/slides/slide-corporate-8-1-2.jpg';
import slide5 from '../../Assests/Banners/banner4.png';

const HomePage = () => {
  return (
    <Carousel 
      interval={1000} 
      controls={true} 
      indicators={true} 
      className="w-100"
      prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
      nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide1}
          alt="Slide 1"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide2}
          alt="Slide 2"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide3}
          alt="Slide 3"
        />
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide4}
          alt="Slide 4"
        />
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slide5}
          alt="Slide 5"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default HomePage;
