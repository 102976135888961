import React from "../ChatBot/ChatBot.css";

const ChatBox = () => {
  return (
    <div className="chatBox">
      <div className="chatBoxHeader">
        <h3>Message Us</h3>
        <p>
          <i className="fa fa-times"></i>
        </p>
      </div>
      <div className="chatBoxBody">
        <div className="chatBoxBodySend">
          <p>This is my message.</p>
          <span>12:00</span>
        </div>
        <div className="chatBoxBodyReceive">
          <p>This is my message.</p>
          <span>12:00</span>
        </div>
        {/* Add more messages as needed */}
      </div>
      <div className="chatBoxFooter">
        <button id="addExtra">
          <i className="fa fa-plus"></i>
        </button>
        <input placeholder="Enter Your Message" type="text" />
        <i className="send far fa-paper-plane"></i>
      </div>
    </div>
  );
};

const ChatButton = () => {
  return (
    <div className="chatButton">
      <span></span>
    </div>
  );
};

const Modal = () => {
  return (
    <div className="modal">
      <div className="modalContent">
        <span className="modalCloseButton">&times;</span>
        <h1>Add What you want here.</h1>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div>
      <ChatBox />
      <ChatButton />
      <Modal />
    </div>
  );
};

export default App;
