import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const ReturnPolicyHeader = () => {
  const navigate = useNavigate();

  return (
    <section
      className="page-header page-header-modern page-header-lg"
      style={{
        backgroundColor: "#e3eff9",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 className="text-color-dark font-weight-bold">
              REFUND AND RETURN POLICY
            </h1>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
              <li>
                <a
                  role="button"
                  onClick={() => navigate("/")}
                  className="text-color-default text-color-hover-primary text-decoration-none"
                >
                  HOME
                </a>
              </li>
              <li className="active">Return Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const ReturnPolicy = () => {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="tremspolicy_wrapper">
      <ReturnPolicyHeader />
      <div className="container">
        <div className="tremspolicy_content">
          <div className="termspolicy_content__wrapper clearfix">
            <p className="f16 mb20">
              Findpath is pleased to provide a money-back guarantee of 7 days.
              However, this is valid only, if a customer buys 2 pieces and
              places the order via online mode. We urge the customers to install
              and test the device within 7 days of receiving it to ensure a
              fully satisfied purchase.
              <br />
              <br />
              Please inform us if you find any physical damage within 24 hours
              of the delivery date. For all the refunds, simply return the
              product with the original and complete packaging with all
              additions. Refund will be done within 7 business days.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className=" mb-3 mt-3">
              The device must be originally given in a good and systematic
              manner to qualify for the refund.
            </h3>
            <p className="f16">
              Note; - We provide 1 year warranty on Findpath GPS tracking
              devices when covered by the Findpath Service package. Devices
              covered by Findpath provide a standard one-year warranty. By any
              means, no replacement will be given if faulty units are there or
              if any physical damage is there from the customer's side. No
              return policy is applicable on physical damage or any burn. If the
              fault is with your vehicle then it is not the responsibility of
              Findpath, we appreciate your cooperation.
              <br />
              <br />
              The policy doesn't count on fixed-term contracts like Client
              Service Agreements. Refer to each contract's terms.
              <br />
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">7-Day Money Back Guarantee</h3>
            <p className="f16">
              Findpath is pleased that we provide a 7-day money-back guarantee
              starting from the date when the device is installed. We urge you
              to install the device within 7 days of receiving the device to
              confirm that you are satisfied with your purchase.
            </p>

            <h5>After 7 Days</h5>
            <p className="f16">No refund will be given after 7 days</p>
            <h5>Claims </h5>
            <p className="f16">
              Any claims regarding Findpath must be made in writing within 7
              days of the incident.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
              Return of Damaged or not working products
            </h3>
            <p className="f16 mb20">
              If you believe you have received a product that is damaged or not
              in working condition or if the product stops working after the
              installation, please return it immediately for an exchange this
              point counts when the customer contacts Findpath within 24 hours
              of buying the products. If a device is considered to be a
              defective piece, Findpath will ship the renovated piece with free
              charge. we are not responsible for any damage or loss to products
              in shipping from your location to Findpath.
            </p>
            <h5>Rewards</h5>
            <p className="f16 mb20">
              Once you've decided to purchase services or revivals, you cannot
              cancel them or receive a refund.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Contact Us</h3>
            <p className="f16 mb20">
              If you have any questions or concerns about this Return Policy, please contact us at:{" "}
              <a role="button" className="text-color-prime">
                support@findpath.co.in
              </a>
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReturnPolicy;
