import React from "react";
import { useNavigate } from "react-router";
import customHeaderBg from "../../img/custom-header-bg.jpg";
import customHeaderBg_2 from "../../img/parallax/parallax-7.jpg";
import customHeaderBg_3 from "../../img/parallax/parallax-2.jpg";
import customHeaderBg_4 from "../../img/parallax/parallax-2.jpg";
import parallax2 from "../../img/parallax/parallax-2.jpg"; // Import image from src directory
import parallax3 from "../../img/parallax/parallax-3.jpg"; // Import image from src directory

const FuelMonitoringSolution = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg mb-0"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Video Telematics Solution
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Video Telematics Solution</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Parallax sections */}
      <section
        className="parallax section section-text-light section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-3">Efficient Routes</h4>
              <p className="mb-0">
                FindPath's Video Telematics Solution enhances safety by
                providing continuous video surveillance of your 4-wheelers. It
                helps in monitoring driver behaviour, detecting potential
                hazards, and ensuring that all safety protocols are followed,
                reducing the risk of accidents and improving overall road
                safety.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="parallax section section-parallax section-center m-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5}'
        style={{ backgroundImage: `url(${customHeaderBg_2})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 py-5 my-5">
              <h4 className="mb-0 text-dark">Driver Monitoring</h4>
              <h5 className="text-dark"></h5>
              <p className="mb-0">
                The solution offers comprehensive driver monitoring for
                4-wheelers, including video recording and behaviour analysis.
                This feature allows fleet managers to track driving patterns,
                assess performance, and address any issues such as speeding or
                harsh braking, promoting safer driving practices.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Half section */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <section className="section section-primary ps-4 pe-4 border-top-0 h-100 m-0">
              <div className="row">
                <div className="col py-5 my-5">
                  <h4 className="mb-3">Real-Time Alerts</h4>
                  <p className="mb-0">
                    FindPath provides real-time alerts based on video footage
                    and telematics data. This includes immediate notifications
                    of unusual events or driving behaviour, allowing for quick
                    intervention and corrective actions to ensure the safety and
                    security of your 4-wheelers.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax border-top-0 h-100 m-0"
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5}'
              style={{ backgroundImage: `url(${customHeaderBg_3})` }}
              // minHeight: "260px",
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Parallax Sections */}
      <div className="container-fluid">
        {/* First Row with Parallax on Left */}
        <div className="row">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax2})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left py-5">
                  <div className="p-3">
                    <h4 className="mb-3">Route Optimization</h4>
                    <p className="mb-0">
                      The solution includes route optimization features to
                      improve the efficiency of 4-wheeler fleet operations. By
                      analyzing travel data and video footage, FindPath helps in
                      planning the most efficient routes, reducing fuel
                      consumption, and saving time, enhancing overall
                      operational efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row with Parallax on Right */}
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5 ms-auto">
                  <div className="p-3">
                    <h4 className="mb-3">Data Insights</h4>
                    <p className="mb-0">
                      FindPath's Video Telematics Solution offers valuable data
                      insights derived from video recordings and telematics
                      data. This includes detailed reports on driver behaviour,
                      vehicle performance, and operational efficiency, helping
                      you make informed decisions and improve the management of
                      your 4-wheeler fleet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${parallax3})`,
                backgroundPosition: "100%",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
              }}
              data-plugin-parallax
              data-plugin-options='{"speed": 1.5, "horizontalPosition": "100%"}'
            ></section>
          </div>
        </div>
      </div>

      {/* Horizontal line */}
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="solid my-5" />
          </div>
        </div>
      </div>

      {/* Scrollable parallax section */}
      <section
        className="parallax section section-text-light section-parallax section-center py-0 my-0"
        data-plugin-parallax
        data-plugin-options='{"speed": 1.5, "scrollableParallax": true, "scrollableParallaxMinWidth": 991, "startOffset": 10, "cssProperty": "width", "cssValueStart": 40, "cssValueEnd": 100, "cssValueUnit": "vw"}'
        style={{ backgroundImage: `url(${customHeaderBg_4})` }}
      >
        <div className="d-flex justify-content-center">
          <div className="scrollable-parallax-wrapper">
            <div className="container py-5 my-5">
              <div className="row justify-content-center py-5 my-5">
                <div className="col-lg-9 py-5 my-5">
                  <h4 className="mb-3">Live Streaming</h4>
                  <p className="mb-5">
                    The solution provides live streaming of video footage from
                    your 4-wheelers. This feature allows real-time monitoring of
                    vehicle activities, enabling fleet managers to oversee
                    operations, respond promptly to incidents, and ensure that
                    vehicles are operating within the defined parameters.
                  </p>
                  <h4 className="mb-3">Incident Recording</h4>
                  <p className="mb-0">
                    FindPath's Video Telematics Solution records incidents and
                    critical events involving your 4-wheelers. This feature
                    ensures that all significant occurrences are documented,
                    providing evidence for investigations, insurance claims, and
                    improving overall safety protocols.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FuelMonitoringSolution;
