import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MiddleImgBar.css";

const MiddleImgBar = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="thumb-gallery-wrapper">
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={null}
        controls={true}
        indicators={false}
      >
        {images.map((src, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={src}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="d-flex justify-content-center align-items-center mt-3">
        {images.map((src, index) => (
          <div
            key={index}
            className={`thumb mx-1 ${
              index === activeIndex ? "border border-primary" : ""
            }`}
            onClick={() => handleSelect(index)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={src}
              alt={`Thumbnail ${index + 1}`}
              className="img-thumbnail"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MiddleImgBar;
