import React from 'react';
import { Link } from 'react-router-dom';

const SearchForm = () => {
  return (
    <form action="https://www.okler.net/previews/porto/10.2.0/page-search-results.html" method="get">
      <div className="input-group mb-3 pb-1">
        <input
          className="form-control text-1"
          placeholder="Search..."
          name="s"
          id="s"
          type="text"
        />
        <button type="submit" className="btn btn-dark text-1 p-2">
          <i className="fas fa-search m-2"></i>
        </button>
      </div>
    </form>
  );
};

const Categories = () => {
  const categories = [
    { name: 'Findpath Lite', link: '/product/findpath-lite' },
    { name: 'Findpath Pro', link: '/product/findpath-pro' },
    { name: 'Findpath Go', link: '/product/findpath-go' },
    { name: 'Findpath Go Plus', link: '/product/findpath-go-plus' },
    { name: 'Findpath E-Lock', link: '/product/findpath-e-lock' },
    { name: 'Findpath Dashcams', link: '/product/findpath-dashcams' },
    { name: 'Findpath Fuel Sensor', link: '/product/findpath-fuel-sensor' }
  ];

  return (
    <>
      <h5 className="font-weight-semi-bold pt-3">Categories</h5>
      <ul className="nav nav-list flex-column">
        {categories.map(category => (
          <li className="nav-item" key={category.name}>
            <Link className="nav-link" to={category.link}>{category.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

const Tags = () => {
  const tags = [
    'Tracker', 'GPS', 'Findpath', 'IdCard', 'Tech', 'Track', 'Promo', 'Reading', 'Social', 'New'
  ];

  return (
    <>
      <h5 className="font-weight-semi-bold pt-5">Tags</h5>
      <div className="mb-3 pb-1">
        {tags.map(tag => (
          <a href="#" key={tag}>
            <span className="badge badge-dark badge-sm rounded-pill text-uppercase px-2 py-1 me-1">
              {tag}
            </span>
          </a>
        ))}
      </div>
    </>
  );
};

const Product = ({ product }) => {
  return (
    <div className="product row row-gutter-sm align-items-center mb-4">
      <div className="col-5 col-lg-5">
        <div className="product-thumb-info border-0">
          <Link to={product.link}>
            <div className="product-thumb-info-image">
              <img alt={product.name} className="img-fluid" src={product.image} />
            </div>
          </Link>
        </div>
      </div>
      <div className="col-7 col-lg-7 ms-md-0 ms-lg-0 ps-lg-1 pt-1">
        <Link to={product.link} className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-2">
          {product.category}
        </Link>
        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
          <Link to={product.link} className="text-color-dark text-color-hover-primary text-decoration-none">
            {product.name}
          </Link>
        </h3>
        <div title={`Rated ${product.rating} out of 5`}>
          <input
            type="text"
            className="d-none"
            value={product.rating}
            title=""
            data-plugin-star-rating
            data-plugin-options="{'displayOnly': true, 'color': 'dark', 'size':'xs'}"
          />
        </div>
        <p className="price text-4 mb-0">
          <span className="sale text-color-dark font-weight-semi-bold">${product.salePrice}</span>
          <span className="amount">${product.price}</span>
        </p>
      </div>
    </div>
  );
};



const Sidebar = () => {
  return (
    <aside className="sidebar">
      <SearchForm />
      <Categories />
      <Tags />
    
    </aside>
  );
};

export default Sidebar;
