import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { countries, states as allStates } from "../../js/country-state"; // Adjust the import path as necessary
import ContactLogo from "../../Assests/ContactLogo.png";

const ContactUs = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "", // Corrected field name to match with the backend
    country: "",
    state: "",
    city: "",
    message: "",
  });

  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState({});
  const [alertClass, setAlertClass] = useState("");
  const [statesList, setStatesList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update statesList when country changes
    if (formData.country) {
      const selectedCountryStates = allStates[formData.country] || [];
      setStatesList(selectedCountryStates);
      setFormData((prevData) => ({
        ...prevData,
        state: "", // Reset state when country changes
      }));
    } else {
      setStatesList([]);
    }
  }, [formData.country]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Please enter your name.";
    }
    if (!formData.email) {
      newErrors.email = "Please enter your email address.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.number) {
      newErrors.number = "Please enter your phone number."; // Corrected error message key to match the field
    }

    if (!formData.city) {
      newErrors.city = "Please choose your city.";
    }
    if (!formData.country) {
      newErrors.country = "Please choose your country.";
    }
    if (!formData.state) {
      newErrors.state = "Please choose your state.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setStatus("Please fill out all required fields correctly.");
      setAlertClass("alert alert-danger d-block mt-4");
      return;
    }

    axios
      .post("http://findpath.co.in/Backend/send_email.php", formData)
      .then((response) => {
        if (response.data.success) {
          setStatus("Success! Your message has been sent.");
          setAlertClass("alert alert-success d-block mt-4");
          setFormData({
            name: "",
            email: "",
            number: "",
            country: "",
            state: "",
            city: "",
            message: "",
          });
          navigate("/thank-you"); // Navigate to the thank you page
        } else {
          setStatus(`Error! ${response.data.message}`);
          setAlertClass("alert alert-danger d-block mt-4");
        }
      })
      .catch((error) => {
        setStatus(`Outer Error! ${error.message}`);
        setAlertClass("alert alert-danger d-block mt-4");
      });
  };

  const inputStyle = { backgroundColor: "#e3eff9" };

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div role="main" className="main">
      <section
        className="page-header page-header-modern page-header-lg"
        style={{ backgroundColor: "#e3eff9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">Contact us</h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    href="HomePage.jsx"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active">CONTACT US</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-transparent position-relative border-0 z-index-1 m-0 p-0">
        <div className="container py-5 ">
          <div className="row justify-content-center">
            <div className="col-lg-9 text-center">
              <div className="overflow-hidden">
                <span
                  className="d-block top-sub-title text-color-primary appear-animation animated maskUp appear-animation-visible"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay="250"
                  style={{ animationDelay: "250ms" }}
                >
                  YOUR TRUSTED CHOICE
                </span>
              </div>
              <div className="overflow-hidden mb-3">
                <h2
                  className="font-weight-bold text-9 text-lg-10 text-color-dark line-height-1 mb-0 appear-animation animated maskUp appear-animation-visible"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay="500"
                  style={{ animationDelay: "500ms", padding: "5px" }}
                >
                  India's Leading GPS Brand
                </h2>
              </div>
              <div className="overflow-hidden mb-3">
                <p
                  className="font-weight-bold text-3-5 mb-0 appear-animation animated maskUp appear-animation-visible"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay="750"
                  style={{ animationDelay: "750ms" }}
                >
                  Experience top-tier tracker solutions, expertly tailored for
                  every make and models.
                </p>
              </div>
              <div className="overflow-hidden">
                <a
                  role="button"
                  href="tel:+919958799582" // Replace with your phone number
                  className="d-inline-block custom-link-font-weight-5 text-color-dark text-color-hover-primary text-decoration-none font-weight-bold appear-animation animated maskUp appear-animation-visible"
                  data-appear-animation="maskUp"
                  data-appear-animation-delay="1000"
                  style={{ animationDelay: "1000ms" }}
                >
                  CONTACT US NOW
                  <svg
                    className="ms-2"
                    version="1.1"
                    viewBox="0 0 15.698 8.706"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polygon
                      stroke="#000"
                      strokeWidth="0.1"
                      fill="none"
                      points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353"
                    ></polygon>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container py-4 my-5">
        <div className="row align-items-center">
          <div
            className="col-lg-4 col-xl-4 offset-xl-1 mb-5 mb-lg-0"
            style={{
              position: "relative",
              top: "-25px",
            }}
          >
            {/* Add your logo here */}
            <div className="mb-4 d-flex justify-content-center">
              <img
                src={ContactLogo}
                alt="Company Logo"
                className="img-fluid contact-from-logo"
              />
            </div>
            <div className="overflow-hidden">
              <h2 className="text-color-dark font-weight-bold line-height-3 text-5-5 mb-0">
                C-591, Avantika Rohini, Sector-1 Delhi - 110085
              </h2>
            </div>
            <div className="overflow-hidden">
              <a
                role="nutton"
                href="https://www.google.com/maps?q=28.704134, 77.099506" // Direct Google Maps URL with location
                target="_blank"
                onClick={() => navigate("#get-direction")}
                className="d-inline-block custom-text-underline-1 font-weight-bold border-color-primary text-decoration-none text-3-5"
              >
                GET DIRECTIONS
              </a>
            </div>
            <ul className="list list-unstyled text-color-dark font-weight-bold text-4 py-2 my-4">
              <li className="d-flex align-items-center mb-2">
                <i className="icons icon-envelope text-color-dark me-2"></i>
                Email:{" "}
                <a
                  href="mailto:support@findpath.co.in"
                  className="text-color-dark text-color-hover-primary text-decoration-none ms-1"
                >
                  support@findpath.co.in
                </a>
              </li>
              <li className="d-flex align-items-center mb-0">
                <i className="icons icon-phone text-color-dark me-2"></i>
                Phone:{" "}
                <a
                  href="tel:+919958799582"
                  className="text-color-dark text-color-hover-primary text-decoration-none ms-1"
                >
                  +91 9958799582
                </a>
              </li>
            </ul>
            <p className="mb-0">
              For all your tracking needs, we're here to help. Visit us, call,
              or email during business hours for quick and professional support.
              Our team is dedicated to providing you with the best service and
              reliable solutions.
            </p>
          </div>
          <div className="col-lg-8 col-xl-5 offset-lg-1">
            <form
              id="contact-form"
              method="POST"
              className="contact-form custom-form-style-1"
              onSubmit={handleSubmit}
            >
              <div className={alertClass} role="alert">
                {status}
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">Full Name</label>
                  <input
                    type="text"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                      // Keep only alphabetic characters and convert to lowercase
                      const value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                      handleChange({
                        target: {
                          name: "name",
                          value: value,
                        },
                      });
                    }}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">Phone Number</label>
                  <input
                    type="tel"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.number ? "is-invalid" : ""
                    }`}
                    name="number"
                    value={formData.number}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits and ensure the length does not exceed 10
                      if (/^\d*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                  />
                  {errors.number && (
                    <div className="invalid-feedback">{errors.number}</div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">Country</label>
                  <select
                    name="country"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.country ? "is-invalid" : ""
                    }`}
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name} {country.dialCode}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="invalid-feedback">{errors.country}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">State</label>
                  <select
                    name="state"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.state ? "is-invalid" : ""
                    }`}
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">Select State</option>
                    {statesList.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <div className="invalid-feedback">{errors.state}</div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label mb-1 text-2">City</label>
                  <input
                    type="text"
                    className={`form-control text-3 h-auto py-2 ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  {errors.city && (
                    <div className="invalid-feedback">{errors.city}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="form-label mb-1 text-2">Message</label>
                  <textarea
                    className={`form-control text-3 h-auto py-2 ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    name="message"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className="form-group col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary btn-modern font-weight-bold custom-btn-border-radius custom-btn-arrow-effect-1 text-3 px-5 py-3"
                    data-loading-text="Loading..."
                  >
                    Submit
                    <svg
                      className="ms-2"
                      version="1.1"
                      viewBox="0 0 15.698 8.706"
                      width="17"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <polygon
                        stroke="#FFF"
                        strokeWidth="0.1"
                        fill="#FFF"
                        points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="">
        {/* Google Maps iframe */}
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13996.098965242782!2d77.0640787!3d28.7188066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d07ea38f9eb41%3A0xfd8183b4b2eadd0e!2sFindpath!5e0!3m2!1sen!2sin!4v1720178693798!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
