import React from 'react';
import Slider from 'react-slick';
import logo1 from '../../Assests/logos/logo1.png';
import logo2 from '../../Assests/logos/logo2.png';
import logo3 from '../../Assests/logos/logo3.png';
import logo4 from '../../Assests/logos/logo4.png';
import logo5 from '../../Assests/logos/logo5.png';
import logo6 from '../../Assests/logos/logo6.png';

const LogosSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Number of logos visible at once
    slidesToScroll: 1, // Number of logos to scroll at once
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="section bg-transparent position-relative border-0 z-index-1 m-0 p-0">
      <div className="container py-4">
      <div className="row justify-content-center pb-3 mb-4">
          <div className="col text-center">
            <h2 className="font-weight-bold text-color-dark line-height-1 mb-0">
              Our Clients
            </h2>
            <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
              <hr className="my-0" />
            </div>
            <p className="font-weight-bold text-3-5 mb-1">
            Our clients trust FindPath for reliable tracking solutions, benefiting from real-time updates,
            </p>
            <p className="font-weight-light text-3-5 mb-0">
            easy-to-use features, and theft prevention for all their tracking needs.
            </p>
          </div>
        </div>
        <Slider {...settings}>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo1}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '90px' }}
            />
          </div>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo2}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '140px' }}
            />
          </div>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo3}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '140px' }}
            />
          </div>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo4}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '140px' }}
            />
          </div>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo5}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '100px' }}
            />
          </div>
          <div className="col mb-5 d-flex align-items-center justify-content-center">
            <img
              src={logo6}
              alt=""
              className="img-fluid"
              style={{ maxWidth: '100px' }}
            />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default LogosSection;
