import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const ScopeOFWarrantyHeader = () => {
    const navigate = useNavigate();
  
    return (
      <section
        className="page-header page-header-modern page-header-lg"
        style={{
          backgroundColor: "#e3eff9",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Scope of warranty
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active"> Scope of warranty</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };
const ScopeOfWarranty = () => {
    useEffect(() => {
        // Scroll to top on initial load
        window.scrollTo(0, 0);
      }, []);
  return (
    <section className="tremspolicy_wrapper">
      <ScopeOFWarrantyHeader/>
    <div>
    <div className="container">
        <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Findpath Warranty and Refund Policy</h3>
            <p className="f16 mb20">
              The limited warranty provides customers with special legal rights.
              These rights might vary depending on your state, province, or
              jurisdiction. the disclaimers, exclusions, or liability limits in
              this warranty do not apply where law is prohibited by law. To
              fully understand your legal rights, please refer to the laws in
              the area of considering the consumer advisory service.
            </p>
            <p className="f16 mb20">
              Findpath warrants that your Findpath hardware product will be free
              from defects in materials and workmanship for 6 months from the
              date of delivery to the original retail purchaser ("the Warranty
              Period"). If a defect in the Product arises within the Warranty
              Period, Findpath will, at its sole option and subject to
              applicable laws: repair or replace it with a new or a refurbished
              item or part; or, in the event that the faulty item is returned, a
              refund of the original purchase price.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <div className="fw6 pfc4 w100 f30 mb20 mt20">
              This Warranty does not apply to:
            </div>

            <ul className="tremspolicy_list f16">
              <li> Products you purchase from unauthorized resellers; </li>
              <li>
                Instances where the instructions for use and activation of
                Product are not followed;{" "}
              </li>
              <li>
                Products used with a jailbroken or rooted mobile device; or{" "}
              </li>
              <li>
                Products damaged due to accident, unauthorized modification, or
                other causes beyond our reasonable control;{" "}
              </li>
              <li>
                Products purchased by an employer for use by employees at the
                workplace where the product manufacturer has provided warnings
                or instructions to the employer;{" "}
              </li>
              <li>
                Products that are parts of a finished product and for which the
                maker of the product has given the buyer of the part cautions or
                instructions because using the finished product might be
                harmful;{" "}
              </li>
              <li>
                Products meant to be used by experts where the product
                manufacturer has guided about the warnings or instructions to
                the experts;{" "}
              </li>
              <li>
                Physical damage, damage by burn and fire, won’t come under
                return policy{" "}
              </li>
              <li>Products with no warranty applicable after one year;</li>
              <li>
                Instances where there is any fault in your vehicle; the company
                is not responsible for the fault or does not take any
                responsibility;{" "}
              </li>
              <li>
                Returns only if the customer submits the request within 24 hours
                of activation; if that exceeds, Findpath is not responsible for
                the return policy.
              </li>
              <li>
                The warranty is in addition to any rights you may have under
                applicable law, including the Indian Consumer Protection Act
                (CPA 1986). If you believe you are entitled to a remedy under
                the CPA or other local laws, please contact us at
                www.Findpath.in.
              </li>
            </ul>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Warranty and Refund Policy</h3>
            <h5>Implied Warranties:</h5>
            <p className="f16 mb20">
              All warranties of merchantability or fitness for a particular
              purpose regarding the products are limited to the duration of the
              applicable express warranty. All other express or implied
              conditions, representations, and warranties, including any implied
              warranty of non-infringement, are disclaimed. Note that some
              jurisdictions do not allow limitations on how long can give
              warranty lasts, so the above limitations may not apply to you.
              This warranty provides specific legal rights, and you may have
              other rights that vary by jurisdiction.
            </p>
            <h5>Claims Process:</h5>
            <p className="f16 mb20">
              To seek remedies under warranty, Findpath must receive your claim
              before the end of the warranty period. You must obtain a Return
              Product Authorization (RPA) from Findpath and return the defective
              device along with proof of purchase to the address specified by
              Findpath in connection with the RPA. The consumer is responsible
              for the cost of shipping the device to Findpath. By sending the
              device, you agree to transfer ownership to Findpath.
            </p>

            <p className="f16 mb20">
              If the product has a one-year replacement warranty and there are
              technical faults such as signal loss, live location problems, or
              malfunctioning, Findpath will replace it with a new product. The
              warranty will continue from the original date of activation. Any
              product returned without a valid warranty claim or an RPA may be
              rejected, returned at the sender’s cost (subject to prepayment),
              or disposed of at Findpath's sole discretion.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Findpath Refund Policy:</h3>

            <p className="f16 mb20">
              If you are the original purchaser of the product and are not
              satisfied, you may return it in its original condition within
              seven (7) days from the date of delivery for a full refund. This
              policy applies only to products purchased directly from Findpath
              or its authorized reseller and is in addition to your legal rights
              and the six-month warranty.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Important Return Instructions:</h3>
            <p className="f16 mb20">
              - Only orders placed directly through our authorized reseller are
              eligible for a refund.
            </p>
            <p className="f16 mb20">
              - Returns must be made within 7 days of receiving your product.
            </p>
            <p className="f16 mb20">
              - The order must be returned in proper condition with its original
              packaging.
            </p>
            <p className="f16 mb20">
              - Half returns are not eligible for a refund (e.g., if you ordered
              2 Findpath product, you must return all 2 product to receive a
              refund).
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">
            Refund Instructions:
            </h3>
            
            <p className="f16 mb20">
            1. Submit a return request by selecting Returns and Refunds on the Findpath website.
            </p>
            <p className="f16 mb20">
            2. Within one business day, you will receive an email/SMS with an RPA number and further return instructions.
            </p>
            <p className="f16 mb20">
            3. Use a shipping service that tracks shipments when returning your order. Note that you are responsible for the return shipping costs.
            </p>
            <p className="f16 mb20">
            Once your returned order is received, we will verify that all Findpath tags in your original order are present and conduct a quality check.
            </p>
            <p className="f16 mb20">
            After processing and approving the return, you will receive a confirmation email/SMS with information on when to expect your refund.
            </p>
            <p className="f16 mb20">
            The refund will be issued to your Bank Account.
            </p>
          </div>

          <div className="termspolicy_content__wrapper clearfix">
            <h3 className="mb-3 mt-3">Contact Us</h3>
            <p className="f16 mb20">
              If you have any questions or concerns about this Scope of Warranty, please contact us at:{" "}
              <a role="button" className="text-color-prime">
                support@findpath.co.in
              </a>
              <br />
              <br />
            </p>
          </div>
    </div>
</div>
    </section>
  )
}

export default ScopeOfWarranty
