import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel, Card, Row, Col } from "react-bootstrap";

const Industry = () => {
  const testimonials = [
    {
      text: "Excellent service support and very useful products.",
      author: "Logistics & Transport",
      image: require("../../Assests/industry/logistic-transport.jpg"), // Use require
    },
    {
      text: "Excellent service and products. Come and experience it.",
      author: "Construction & Mining",
      image: require("../../Assests/industry/mining.jpg"), // Use require
    },
    {
      text: "Findpath gps exceeded my expectations with its accurate and reliable navigation. The battery life is impressive and The voice-guided directions are clear.",
      author: "Medical & Healthcare",
      image: require("../../Assests/industry/medical-health.jpg"), // Use require
    },
    {
      text: "Superb quality product with reasonable price and best services.",
      author: "Waste Management",
       image: require("../../Assests/industry/waste-management.jpg"), 
    },
    {
      text: "Great quality products with commendable services.",
      author: "Public Transport",
      image: require("../../Assests/industry/public-transport.jpg"), // Use require
    },
    {
      text: "Best company with best products and services.",
      author: "Rental Vehicles",
      image: require("../../Assests/industry/rental-vehicle.jpg"), // Update the path
    },
    {
      text: "Findpath Provide Best GPS Tracker in Rohini.",
      author: "Agriculture",
      image: require("../../Assests/industry/agriculture.jpg"), // Use require
    },
    {
      text: "Best GPS Tracker Company in Rohini Delhi.",
      author: "FMCG",
      image: require("../../Assests/industry/fmcg.jpg"), // Use require
    },
  ];

  return (
    <section className="section border-0 mb-1 theme-color-matching">
      <div className="container pb-3 my-5">
        <div className="col text-center">
          <h2 className="font-weight-bold text-color-dark line-height-1 mb-0">
            Industries
          </h2>
          <div className="d-inline-block custom-divider divider divider-primary divider-small my-3">
            <hr className="my-0" />
          </div>
        </div>
        <Carousel
          indicators={false}
          controls={true}
          interval={4000}
          pause={false}
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6} className="mb-3">
                  <Card className="custom-border-radius-1 h-100">
                    <Card.Img variant="top" src={testimonial.image} />
                    <Card.Body>
                      <div
                        className="custom-testimonial-style-1 testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote"
                        style={{ textAlign: "left" }}
                      >
                        <div className="testimonial-author">
                          <p>
                            <strong
                              className="font-weight-extra-bold"
                              style={{ textAlign: "left" }}
                            >
                              {testimonial.author}
                            </strong>
                          </p>
                        </div>
                        <p className="text-color-dark text-3 font-weight-light px-0 mb-2">
                          {testimonial.text}
                        </p>
                        <a
                          href="#"
                          className=" font-weight-extra-bold  mt-3"
                          style={{ float: "left" }} // Button aligned to the left
                        >
                          Read More
                          <i className="fas fa-angle-right position-relative top-1 ms-1"></i>
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Industry;
