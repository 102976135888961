import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// BlogHeader Component
const BlogHeader = () => {
  const navigate = useNavigate();

  return (
    <section className="page-header page-header-modern page-header-lg" style={{ backgroundColor: "#e3eff9" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 className="text-color-dark font-weight-bold">Blog</h1>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
              <li>
                <a
                  role="button"
                  onClick={() => navigate("/")}
                  className="text-color-default text-color-hover-primary text-decoration-none"
                >
                  HOME
                </a>
              </li>
              <li className="active">BLOG</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

// BlogPost Component
const BlogPost = ({ date, comments, author, title, summary, imageUrl, postUrl }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(postUrl);
  };

  return (
    <article className="mb-5">
      <div className="card bg-transparent border-0 custom-border-radius-1">
        <div className="card-body p-0 z-index-1">
          <img
            className="card-img-top custom-border-radius-1 mb-2"
            onClick={handleNavigation}
            src={imageUrl || 'path/to/default-image.jpg'} // Fallback image if URL is missing
            alt="Card Image"
            style={{ cursor: "pointer" }}
          />
          <p className="text-uppercase text-color-default text-1 my-2">
            <time pubdate="" dateTime={date}>
              {date}
            </time>
            <span className="opacity-3 d-inline-block px-2">|</span>
            {comments} Comments
            <span className="opacity-3 d-inline-block px-2">|</span>
            {author}
          </p>
          <div className="card-body p-0">
            <h4 className="card-title text-5 font-weight-bold pb-1 mb-2">
              <span
                className="text-color-dark text-color-hover-primary text-decoration-none"
                onClick={handleNavigation}
                style={{ cursor: "pointer" }}
              >
                {title}
              </span>
            </h4>
            <p className="card-text mb-2">{summary}</p>
            <button
              onClick={handleNavigation}
              className="btn btn-link font-weight-semibold text-decoration-none text-3 ps-0"
            >
              READ MORE
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

// Pagination Component
const Pagination = () => (
  <ul className="custom-pagination-style-1 pagination pagination-rounded pagination-md justify-content-center">
    <li className="page-item">
      <a className="page-link" href="#">
        <i className="fas fa-angle-left"></i>
      </a>
    </li>
    <li className="page-item active">
      <a className="page-link" href="#">
        1
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="#">
        2
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="#">
        3
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="#">
        <i className="fas fa-angle-right"></i>
      </a>
    </li>
  </ul>
);

// BlogMainContent Component
const BlogMainContent = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    
    axios.get('http://localhost:8000/admin/fetch_posts.php')
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the posts!", error);
        setError("Failed to load posts.");
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading posts...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div
      className="col-lg-8 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
      data-appear-animation="fadeInUpShorter"
      data-appear-animation-delay="1600"
      style={{ animationDelay: "1600ms" }}
    >
      {posts.map(post => (
        <BlogPost
          key={post.id}
          date={new Date(post.created_at).toLocaleDateString()}
          comments="0" // Replace with actual comments count if available
          author="Author Name" // Replace with actual author if available
          title={post.title}
          summary={post.description.substring(0, 100) + '...'} // Shortened description
          imageUrl={post.thumbnail || 'path/to/default-image.jpg'} // Fallback image
          postUrl={`/blog/${post.slug_url}`}
        />
      ))}
      <Pagination />
    </div>
  );
};

// BlogPage Component
const BlogPage = () => {
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BlogHeader />
      <div className="py-3 my-4 d-flex justify-content-center container">
        <BlogMainContent />
      </div>
    </div>
  );
};

export default BlogPage;
